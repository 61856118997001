import React from 'react';
import Main from '../components/main/Main'
export default function (props) {


    return (<Main title={""} is_home={true}>
        <div className="alert alert-info">
            <strong><i className="fas fa-hourglass-start"></i></strong> Função em desenvolvimento.            
        </div>
    </Main>)
}