import React from 'react'
import Select from 'react-select'


// export const InputSelect = (data, value, onChange, multi = false) => {
export const InputSelect = (props) => {

    const onChange = (value) => {
        props.setValue(value)
        props.onChange(value)
    }

    return <div className="d-flex flex-column">

        <div className="p-0 fw-bold">{props.caption}</div>
        <div className="p-0">
            <Select                
                placeholder={props.placeholder || 'Selecione'}
                // id={"inputSelect" + getThemeCSS()}
                value={props.value}
                onChange={onChange}
                isMulti={props.isMulti}
                //isClearable={true}
                isSearchable={true}
                options={props.data}
                fullWidth
                getOptionLabel={option => option.nome}
                getOptionValue={option => option.id}
            />
        </div>
    </div>



}