import React from 'react'

export default (props) => {

    const openWhatsWeb = (e) => {
        e.preventDefault()
        let whatsAppNumber = document.getElementById("whatsapp_ddd").value + document.getElementById("whatsapp_number").value
        var win = window.open('https://wa.me/55' + whatsAppNumber, '_blank');
    }


    return (
        <form onSubmit={openWhatsWeb}>
            <div className="p-4">
                <div className="d-flex justify-content-center mb-3 p-4">
                    <div className="p-4  bg-primary text-white">
                        <div className="d-flex flex-column">
                            <h1>Abrir conversa no WhatsApp Web</h1>
                            <h5>Informe o DDD e o número abaixo:</h5>
                            <div className="d-flex">
                                <input type="text" readOnly required className="text-center w-25 me-2 form-control" value="51" id="whatsapp_ddd" maxLength="2" placeholder="51" name="whatsapp_ddd" />
                                <input type="text" required className="form-control" id="whatsapp_number" maxLength="9" placeholder="123456789" name="whatsapp_number" />
                            </div>
                            <button type="submit" className="mt-2 btn btn-secondary">Abrir</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}