import React, { useState, useEffect, createContext, useContext, useCallback } from 'react';
import token from '../config/token'
import { getDataSourceByPost } from '../api/dataSource'
// const jwt = require('jsonwebtoken')
// import * as jose from 'jose'
// import jwt from 'jsonwebtoken'

import { isExpired, decodeToken } from "react-jwt";
// import { useJwt } from "react-jwt";
const tokenJWT = token.get

// console.log(tokenJWT)




function AuthProvider(props) {
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);
  // const { decodedToken, isExpired } = useJwt(tokenJWT);


  useEffect(() => {
    (async function () {
      const result = await getUser()

      if (result.isOk) {
        setUser(result.data)
      }

      setLoading(false)
    })()
  }, [])



  // CheckUser
  const getUser = async () => {

    try {
      let User
      let isOk = false
      const tokenToCheck = token.get

      if (tokenToCheck) {
        const getUserRes = await getDataSourceByPost('/auth/checktoken', { "token": tokenToCheck })
        if (!getUserRes.isOk) throw new Error(getUserRes.message)
        token.set(getUserRes.data.token)
        const myDecodedToken = decodeToken(tokenToCheck);
        User = myDecodedToken.user
        isOk = true
      }

      return {
        isOk: isOk,
        data: User
      };
    }
    catch (e) {
      console.log(e)
      return {
        isOk: false
      }
    }
  }

  // Login
  const signIn = useCallback(async (email, password) => {
    try {
      const loginRes = await getDataSourceByPost('/auth/login', { "email": email, "password": password })

      if (!loginRes.isOk) throw new Error(loginRes.message)

      if (loginRes.isOk) {
        const userData = await loginRes.data
        token.set(userData.token)
        const tokenDecoded = decodeToken(userData.token)        
        setUser(tokenDecoded.user)
      }
      return {
        auth: true
      };
    } catch (error) {

      return {
        auth: false,
        message: error.message
      }

    }
  }, []);

  const signOut = useCallback(() => {
    token.delete()
    setUser();
  }, []);


  return (
    <AuthContext.Provider value={{ user, signIn, signOut, loading, }} {...props} />
  );
}



const AuthContext = createContext({});
const useAuth = () => useContext(AuthContext);


export { AuthProvider, useAuth }