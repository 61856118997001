import React, { useState, useEffect, useCallback, Fragment } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import * as XLSX from "xlsx";
import { postData, getDataSourceByPost, getDataSource } from '../../../api/dataSource'
import Main from '../../../components/main/Main'
import SibeComponents, { ListBox, TextBox, TextBoxRef } from '../../../components/drop-down-box/SibeComponents'
let sibeComponents = new SibeComponents();
export default function (props) {
    useEffect(() => {
        const load = async () => {
            //setPolosData(props.privileges.polos)

            //const consultoresDataResponse = await getDataSource('/comercial/lead-consultor/0')
            const origensDataResponse = await getDataSource('/comercial/lead-origem', 'POST')
            const situacoesDataResponse = await getDataSource('/comercial/lead-situacao', 'POST')

            setConsultoresData([])
            setOrigensData(origensDataResponse.data)
            setSituacoesData(situacoesDataResponse.data)

        }
        load()

    }, []);

    const onChangePoloValue = async (value) => {

        console.log(value)

        setPolo(value)
        const consultorResponse = await getDataSource('/comercial/lead-consultor/' + value.id, 'POST')
        setConsultoresData(consultorResponse.data)
    }

    const onChangeConsultorValue = async (value) => setConsultor(value)
    const onChangeOrigemValue = async (value) => setOrigem(value)
    const onChangeSituacaoValue = async (value) => setSituacao(value)



    const [messages, setMessages] = useState("")
    const [loading, setLoading] = useState(false)
    const [leads, setLeads] = useState()
    const [registrosEncontrados, setRegistrosEncontrados] = useState("")


    const [polo, setPolo] = useState('')
    const [consultor, setConsultor] = useState('')
    const [origem, setOrigem] = useState('')
    const [situacao, setSituacao] = useState('')

    const [consultoresData, setConsultoresData] = useState([])
    const [origensData, setOrigensData] = useState([])
    const [situacoesData, setSituacoesData] = useState([])





    const onClick_LimparResultados = () => {
        setLeads(null);
        setMessages("")
        setRegistrosEncontradosText()
    }

    const onChange_LerPlanilha = async (e) => {
        setLoading(true)
        e.stopPropagation()
        e.preventDefault()
        var arquivo = e.target.files[0]

        if (e.target.files[0].type != "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" &&
            e.target.files[0].type != "application/vnd.ms-excel"
        ) {
            document.getElementById("file").value = ""
            setMessages(
                <React.Fragment>
                    <p className="mt-4 text-danger">
                        O arquivo selecionado para importação deve ser no formato de planilha com a extensão .xls ou xlsx.
                    </p>
                </React.Fragment>
            )

            setLoading(false)
            return
        }

        try {
            const reader = new FileReader()
            reader.onload = (evt) => {
                const bstr = evt.target.result
                const wb = XLSX.read(bstr, { type: "binary" })
                const wsname = wb.SheetNames[0]
                const ws = wb.Sheets[wsname]
                const data = XLSX.utils.sheet_to_json(ws, { header: 1 })

                let fatoIndicadoresData = []
                for (var i = 1; i < data.length; i++) {
                    if (data[i][0]) {
                        fatoIndicadoresData.push(
                            {
                                row: i + 3,
                                polo_id: polo.id,
                                consultor_responsavel_id: consultor.id,
                                origem_id: origem.id,
                                situacao_id: situacao.id,

                                nome: data[i][0],
                                telefone: data[i][1],
                                email: data[i][2],
                                municipio: data[i][3],
                                curso_area_interesse: data[i][4],
                            }
                        )
                    }
                }
                setRegistrosEncontradosText(fatoIndicadoresData.length)
                setLeads(fatoIndicadoresData)
            }
            reader.readAsBinaryString(arquivo);
            setLoading(false)

        } catch (error) {
            console.log(error)
            setLoading(false);
        }
    }


    const setRegistrosEncontradosText = (quantidade) => {
        if (quantidade > 0) {
            setRegistrosEncontrados(`${quantidade} registros encontrados.`)
        } else {
            setRegistrosEncontrados("")
        }
    }

    const onClick_EnviarPlanilha = async (e) => {

        if (!polo) {
            setMessages(<React.Fragment><p className="mt-4 text-danger">Selecione um Polo.</p></React.Fragment>)
            return
        }
        if (!consultor) {
            setMessages(<React.Fragment><p className="mt-4 text-danger">Selecione um Consultor.</p></React.Fragment>)
            return
        }
        if (!situacao) {
            setMessages(<React.Fragment><p className="mt-4 text-danger">Selecione uma Situação.</p></React.Fragment>)
            return
        }
        if (!origem) {
            setMessages(<React.Fragment><p className="mt-4 text-danger">Selecione uma Origem.</p></React.Fragment>)
            return
        }


        if (!leads) {
            setMessages(
                <React.Fragment>
                    <p className="mt-4 text-danger">Selecione um arquivo.</p>
                </React.Fragment>
            )
            return
        }

        if (leads.length > 1000) {
            setMessages(
                <React.Fragment>
                    <p className="mt-4 text-danger">O número máximo de linhas permitido por arquivo é 6 mil.</p>
                </React.Fragment>
            )
            return
        }

        if (leads == null) {
            setMessages(
                <React.Fragment>
                    <p className="mt-4 text-danger">Selecione uma planilha.</p>
                </React.Fragment>
            )
            return
        }

        setLoading(true);
        let result = await postData('/comercial/lead/inclusao_lote', leads)

        console.log(result)

        if (!result.isOk) {
            setLoading(false);
            const errosResponse = JSON.parse(result.message)

            const errorType = errosResponse.type;
            switch (errorType) {
                case 'invalid_data':
                    console.log('Dados inválidos.');
                    setMessages(
                        <React.Fragment>
                            <p className="mt-4 text-danger">
                                A importação não foi realizada. Foram encontrados {errosResponse.messages.length} erros na planilha enviada. Confira abaixo:
                            </p>
                            <table className="table table-striped bg-light text-dark">
                                <thead><tr><th>Linha</th><th>Campo</th><th>Valor</th></tr></thead>
                                <tbody>
                                    {errosResponse.messages.map((item, i) => {
                                        return (
                                            <tr className='' key={i}>
                                                <td>{item.linha}</td>
                                                <td>{item.campo}</td>
                                                <td>{item.valor}</td>
                                            </tr>
                                        );
                                    })
                                    }</tbody>
                            </table>

                        </React.Fragment>
                    )
                    break;
                case 'no_records':
                    console.log('Nenhum registro encontrado.');
                    setMessages(
                        <React.Fragment>
                            <p className="mt-4 text-danger">Nenhum registro encontrado</p>
                        </React.Fragment>
                    )
                    break;
                case 'unknown_error':
                    console.log('Default error: check backend console log.');
                    setMessages(
                        <React.Fragment>
                            <p className="mt-4 text-danger">Default error: check backend console log</p>
                        </React.Fragment>
                    )
                    break;
                default:
                    console.log('Default error: check backend console log');
                    setMessages(
                        <React.Fragment>
                            <p className="mt-4 text-danger">Default error: check backend console log</p>
                        </React.Fragment>
                    )
                    break;
            }
        }
        else {
            setLoading(false);
            setMessages(
                <React.Fragment>
                    <p className="mt-4 text-success font-weight-bolder"> {result.data.records_count} leads inseridos.</p>
                </React.Fragment>
            )
        }
    }

    return (
        <Main title={'/  Comercial  /  Leads  /  Inclusão Por Lote'} privileges={props.privileges}>


            <div className="row mt-2 p-2">
                <div className="col-4 pt-2 fw-bold">
                    <div className="row mt-2 mb-2">
                        <div className="col-2 pt-2 fw-bold">Polo</div>
                        <div className="col">{sibeComponents.SelectMultiple(props.privileges.polos, polo, onChangePoloValue, false)}</div>
                    </div>
                    <div className="row mt-2 mb-2">
                        <div className="col-2 pt-2 fw-bold">Consultor</div>
                        <div className="col">{sibeComponents.SelectMultipleTEMP(consultoresData, consultor, onChangeConsultorValue, false)}</div>
                    </div>
                    <div className="row mt-2 mb-2">
                        <div className="col-2 pt-2 fw-bold">Situação</div>
                        <div className="col">{sibeComponents.SelectMultiple(situacoesData, situacao, onChangeSituacaoValue, false)}</div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-2 pt-2 fw-bold">Origem</div>
                        <div className="col">{sibeComponents.SelectMultiple(origensData, origem, onChangeOrigemValue, false)}</div>
                    </div>
                </div>
                <div className="col-8 pt-3">

                    <form>
                        <p><a href="/planilha_modelo.xlsx" className="link-primary"> Download da planilha modelo</a></p>
                        <input type="file" id="file" onChange={onChange_LerPlanilha} className="form-control-file"
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                        />
                        <p className="text-primary p-0">{registrosEncontrados}</p>

                        <button type="reset" className="btn btn-link p-0" onClick={onClick_LimparResultados} >Limpar resultados</button>
                    </form>

                </div>
            </div>

            <div className='p-2'>
                <hr />
                <button type="button" onClick={onClick_EnviarPlanilha} className="btn btn-primary">Importar</button>
                {messages}
            </div>



            <Dialog
                open={loading}
                disableBackdropClick={true}
            >
                <DialogTitle id="alert-dialog-title">
                    <div className="d-inline-flex">
                        <div className="mr-2" >Aguarde</div>
                        <div className="spinner-border text-muted"></div>
                    </div>
                </DialogTitle>
            </Dialog>


            <div className="modal" id="myModal">
                <div className="modal-dialog">
                    <div className="modal-content">


                        <div className="modal-header">
                            <h4 className="modal-title">Termo de uso</h4>
                            <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
                        </div>


                        <div className="modal-body">
                            <p className="text-justify">
                                Manifesto o interesse em aderir à Plataforma de Gestão de Conhecimento em Soberania e Segurança Alimentar e Nutricional (PlaGeSSAN). Informo ter experiência, conhecimento e participação anterior em atividades de SSAN que contribuem com a realização do Direito Humano à Alimentação Adequada, conforme os pressupostos da Política Nacional de Segurança Alimentar e Nutricional (PNSAN), explicitados na Lei nº 11.346/2006, no Decreto nº 6.272/2007 e no Decreto nº 7.272/2010, e em outros documentos balizadores de âmbito internacional para ações dessa natureza. Nesse sentido, assumo a responsabilidade de respeitar tais princípios e diretrizes e que estes sejam norteadores dos projetos, comunidades, atividades colaborativas de SSAN apresentadas de forma intersetorial, interdisciplinar e por meio de diálogo de saberes; sejam na lógica de ensino, pesquisa e extensão na SSAN; e não façam uso da plataforma para fins comerciais, de autopromoção ou antiéticos, ratificando e mencionando estas informações no projeto/comunidades/atividades apresentadas. Compreendendo que o não atendimento de tais valores podem acarretar a minha exclusão e das minhas ações/ projetos/ comunidades/ atividades da Plataforma. Ainda, de acordo com a Lei n°12.813/2013, informo não apresentar conflito de interesses com o campo da soberania e segurança alimentar e nutricional, assim, não comprometendo o interesse coletivo ou influenciando de maneira imprópria o desempenho da função pública.
                            </p>
                        </div>
                    </div>
                </div>
            </div>


        </Main >
    );
}