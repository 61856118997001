const key = 'auth'

function getToken() {
    const token = localStorage.getItem(key) || ""
    if (token) return token
    else return ""
}

module.exports = {
    get: getToken(),
    set: (token) => localStorage.setItem(key, token),
    delete: () => localStorage.removeItem(key)
}