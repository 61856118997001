import React, { useState, useEffect } from 'react';
import { Pager, Paging, ColumnChooser, Summary, GroupItem, Grouping, GroupPanel, DataGrid, Column, Selection, Scrolling, Popup, Lookup, Form, HeaderFilter, SearchPanel } from 'devextreme-react/data-grid';
import LeadForm from './lead-form'
import 'devextreme-react/text-area';
import SibeComponents from '../../../components/drop-down-box/SibeComponents'
import { customStoreWithToken, getDataSource, getDataSourceByPost, dxCustomStore } from '../../../api/dataSource'

import { useAuth } from '../../../contexts/auth'
import { GetPolosAccess } from '../../../config/user_profile'

import Main from '../../../components/main/Main'
let sibeComponents = new SibeComponents();

const URL = '/gestao/relacao_alunos_rematricula'

export default function (props) {

    const { user } = useAuth()
    const [dataRows, setDataRows] = useState([])

    const [dataRowsRegistros, setDataRowsRegistros] = useState([])


    const [polosValue, setPolosValue] = useState([])
    const [polosData, setPolosData] = useState([])

    const [anoSemestre, setAnoSemestre] = useState(-1)
    const [anoSemestreData, setAnoSemestreData] = useState([])

    // const [polos, setPolos] = useState([])
    const [formOpen, setFormOpen] = useState(false)
    const [alunoData, setAlunoData] = useState('0')
    const [consultoresData, setConsultoresData] = useState([])

    const [loadComplete, setLoadComplete] = useState(false)

    useEffect(() => {
        const load = async () => {
            const _polos = await GetPolosAccess('comercial_relacao_alunos_rematricula', user.id)
            setPolosData(_polos)

            const anosSemestres = await getDataSource('/comercial/relacao_candidatos_inscritos/list-ano-semestre', 'GET')
            setAnoSemestreData(anosSemestres.data)
            setAnoSemestre(anosSemestres.data[0])


            upDateDataGridRows(polosData, anosSemestres.data[0])
            // updateConsultoresData()
        }
        load()
        setLoadComplete(true)
    }, []);


    const renderBtnEdit = (cellData) => {

        return (
            <button className="m-0 p-0 btn btn-sm" type="button" onClick={async () => {
                setAlunoData(cellData.data)
                setFormOpen(true)
            }}>
                <span><i className="m-0 p-0 me-1 fas fa-pen"></i></span>
                {cellData.data.aluno_nome}
            </button>
        )
    }


    const renderRegistros = (cellData) => {
        let renderCell = cellData.data.registros

        if (cellData.data.registros == 0) {
            renderCell = <div>
                <span className="text-danger"> {cellData.data.registros} </span>
            </div >
        }

        return renderCell
    }

    const groupCellRenderStatus = (data) => {

        const value = `${data.value} ${data.summaryItems[0].value}`

        if (data.value == 'Não Matriculado: Em Curso') {
            return <div className="text-danger">
                {value}
            </div>



        } else return value



    }


    const renderSituacao = (cellData) => {
        let renderCell = cellData.data.rematricula_situacao_nome

        if (cellData.data.rematricula_situacao_id == 1) {
            renderCell = <div>
                <span><i className="text-danger m-0 p-0 me-1 fas fa-question"></i></span>{cellData.data.rematricula_situacao_nome}
            </div>
        }

        if (cellData.data.rematricula_situacao_id == 2) {
            renderCell = <div>
                <span><i className="text-primary m-0 p-0 me-1 fas fa-user-graduate"></i></span>{cellData.data.rematricula_situacao_nome}
            </div>
        }

        if (cellData.data.rematricula_situacao_id == 3) {
            renderCell = <div>
                <span><i className="text-warning m-0 p-0 me-1 fas fa-exclamation"></i></span>{cellData.data.rematricula_situacao_nome}
            </div>
        }

        if (cellData.data.rematricula_situacao_id == 4) {
            renderCell = <div>
                <span><i className="text-success m-0 p-0 me-1 fas fa-business-time"></i></span>{cellData.data.rematricula_situacao_nome}
            </div>
        }

        if (cellData.data.rematricula_situacao_id == 5) {   //Pendência de Docs.
            renderCell = <div>
                <span><i className="text-danger m-0 p-0 me-1 fas fa-hourglass"></i></span>{cellData.data.rematricula_situacao_nome}
            </div>
        }
        if (cellData.data.rematricula_situacao_id == 6) {   //Matrícula Cancelada
            renderCell = <div>
                <span><i className="text-dark m-0 p-0 me-1 fas fa-window-close"></i></span>{cellData.data.rematricula_situacao_nome}
            </div>
        }
        if (cellData.data.rematricula_situacao_id == 7) {   // Pendência Financeira
            renderCell = <div>
                <span><i className="text-danger m-0 p-0 me-1 fas fa-dollar-sign"></i></span>{cellData.data.rematricula_situacao_nome}
            </div>
        }



        // window-close


        // {cellData.data.rematricula_situacao_nome}<span><i className="m-0 p-0 ms-1 fas fa-exclamation"></i></span>

        return renderCell
    }

    const onPolosValueChange = (value) => {
        setPolosValue(value)
        if (loadComplete) upDateDataGridRows(value, anoSemestre)
    }

    const onAnoSemestreValueChange = (value) => {
        setAnoSemestre(value)
        if (loadComplete) upDateDataGridRows(polosValue, value)
    }

    const upDateDataGridRows = async (polos, ano_semestre) => {
        const body = { polos: polos, ano_semestre: ano_semestre }
        const data = await getDataSourceByPost(URL, body)
        setDataRows(data.data)

        const dataRegistros = await getDataSourceByPost(URL + '/registros', body)
        setDataRowsRegistros(dataRegistros.data)





    }


    // const updateConsultoresData = async () => {
    //     const body = { polos: polosData }
    //     const data = await getDataSourceByPost('/gestao/relacao_alunos_rematricula/list-consultor', body)
    //     setConsultoresData(data.data)
    // }

    return (<Main title={'/  Comercial  /  Relação De Alunos Sem Rematrícula'} privileges={props.privileges}>
        <div className="row">
            <div className="col-4 p-0 m-0">
                <div className="d-flex flex-column ps-4">
                    <div className="p-0 m-0 fw-bold">Polos:</div>
                    <div className="p-0 m-0">{sibeComponents.SelectMultiple(polosData, polosValue, onPolosValueChange, true)}</div>
                </div>
            </div>

            {/* <div className="col-2 p-0 m-0">
                <div className="d-flex flex-column ps-3">
                    <div className="p-0 m-0 fw-bold">Ano/Semestre:</div>
                    <div className="p-0 m-0">{sibeComponents.SelectMultiple(anoSemestreData, anoSemestre, onAnoSemestreValueChange, false)}</div>
                </div>
            </div> */}

        </div>
        <DataGrid className="mt-3 border-dark border-top"
            id="grid"
            showBorders={true}
            dataSource={dataRows}
            repaintChangesOnly={true}
            columnAutoWidth={true}
            allowColumnResizing={true}
            allowColumnReordering={true}
            height="70vh"
            width="100%"
        >
            <ColumnChooser enabled={true} height={"90vh"} width={300} allowSearch={true} />
            <Grouping autoExpandAll={false} />
            <GroupPanel visible={true} />
            <Paging defaultPageSize={500} />
            <Pager showPageSizeSelector={true} allowedPageSizes={[50, 100, 250, 500]} showInfo={true} />
            <HeaderFilter visible={true} />
            <SearchPanel visible={true} width={240} placeholder="Pesquisar..." />
            <Scrolling mode="standard" useNativ={true} showScrollbar="always" />
            <Selection mode="single" />

            {/* <Column dataField="semestre" caption="Semestre" groupIndex={0} visible={true} width="100" defaultSortOrder="desc" /> */}
            <Column caption="" alignment="center">
                <Column dataField="status_nome" caption="Status" visible={true} width="150" groupIndex={0} autoExpandGroup={false} groupCellRender={groupCellRenderStatus} />
                <Column dataField="rematricula_situacao_sibe_nome" caption="Situação Sibe" visible={true} width="150" groupIndex={0} autoExpandGroup={false} />
                <Column dataField="polo_nome" caption="Polo" visible={false} autoExpandGroup={false} width="100" />
                <Column dataField="id" caption="Aluno" visible={true} alignment="left" cellRender={renderBtnEdit} allowSorting={false} allowFiltering={false} allowReordering={false} allowGrouping={false} width="250" />
                {/* <Column dataField="aluno_nome" caption="Aluno" visible={true} width="250" /> */}
                <Column dataField="registros" alignment="center" caption="Registros" visible={true} width="100" cellRender={renderRegistros} />
                <Column dataField="rgm" caption="RGM" alignment="center" visible={true} width="100" />
            </Column>

            <Column caption="Vencidos" alignment="center">
                <Column dataField="vencidos_parcelas" caption="QTD" alignment="center" visible={true} width="80" />
                <Column dataField="vencidos_total" caption="Total" alignment="center" visible={true} width="80" format={{ type: 'fixedPoint', precision: 2 }} />
            </Column>
            <Column caption="Pagos" alignment="center">
                <Column dataField="pagos_parcelas" caption="QTD" alignment="center" visible={true} width="80" />
                <Column dataField="pagos_total" caption="Total" alignment="center" visible={true} width="80" format={{ type: 'fixedPoint', precision: 2 }} />
            </Column>
            <Column caption="A Vencer" alignment="center">
                <Column dataField="a_vencer_parcelas" caption="QTD" alignment="center" visible={true} width="80" />
                <Column dataField="a_vencer_total" caption="Total" alignment="center" visible={true} width="80" format={{ type: 'fixedPoint', precision: 2 }} />
            </Column>


            <Column caption="" alignment="center">
                <Column dataField="serie" caption="Série" alignment="center" width="60" visible={true} />
                <Column dataField="ingresso_ano_trimestre" alignment="center" width="60" caption="Ingresso" visible={true} />
                <Column dataField="curso_grau_nome" caption="Grau" visible={false} />
                <Column dataField="curso_nome" caption="Curso" visible={true} width="250" />
                <Column dataField="rematricula_consultor_name" caption="Consultor" visible={true} width="100" />
                <Column dataField="data_matricula" alignment="center" caption="Matrícula" visible={true} dataType={"date"} width="100" />
            </Column>
            <Summary><GroupItem column="id" displayFormat="{0}" summaryType="count" /></Summary>
        </DataGrid>









        {/* <p className="bg-secondary text-white p-2 mt-5 fw-bold">Registros</p> */}

        {/* <DataGrid className="m-0 p-0 border-dark"
            id="grid"
            showBorders={true}
            dataSource={dataRowsRegistros}
            repaintChangesOnly={true}
            columnAutoWidth={true}
            allowColumnResizing={true}
            allowColumnReordering={true}
            height="70vh"
            width="100%"
        >
            <ColumnChooser enabled={true} height={"90vh"} width={300} allowSearch={true} />
            <Grouping autoExpandAll={false} />
            <GroupPanel visible={true} />
            <Paging defaultPageSize={500} />
            <Pager showPageSizeSelector={true} allowedPageSizes={[50, 100, 250, 500]} showInfo={true} />
            <HeaderFilter visible={true} />
            <SearchPanel visible={true} width={240} placeholder="Pesquisar..." />
            <Scrolling mode="standard" useNativ={true} showScrollbar="always" />
            <Selection mode="single" />

            <Column dataField="polo_nome" caption="Polo" visible={true} groupIndex={0} autoExpandGroup={false} />
            <Column dataField="situacao_nome" caption="Situação" visible={true} groupIndex={0} autoExpandGroup={false} />

            <Column dataField="data_hora" alignment="center" dataType="date" caption="Data/Hora" visible={true} width="120" defaultSortOrder="desc" />

            <Column dataField="id" caption="" visible={false} alignment="center" allowSorting={false} allowFiltering={false} allowReordering={false} allowGrouping={false} />
            <Column dataField="aluno_nome" caption="Aluno" visible={true} width="250" defaultSortOrder="asc" />
            <Column dataField="descricao" caption="Descrição" width="350" visible={true} />
            <Column dataField="consultor_nome" caption="Consultor" width="200" visible={true} />
            <Column dataField="matricula_rgm" caption="RGM" alignment="center" visible={true} width="100" />
            <Column dataField="curso_grau_nome" caption="Grau" visible={true} />
            <Column dataField="curso_nome" caption="Curso" visible={true} />


            <Summary><GroupItem column="id" displayFormat="{0}" summaryType="count" /></Summary>
        </DataGrid> */}






        <LeadForm
            setFormOpen={setFormOpen}
            formOpen={formOpen}
            alunoData={alunoData}
            polos={polosValue}
            upDateDataGridRows={upDateDataGridRows}
        // consultoresData={consultoresData}
        />
    </Main>
    )
}