import React from 'react'
import { Popup } from 'devextreme-react/popup';
import ScrollView from 'devextreme-react/scroll-view';

export default (props) => {
  const OnClickCancelar = function () { props.setFormOpen(false) }
  const OnClickSalvar = async function () { props.formBtnSalvarOnClick() }

  return (
    <Popup
      visible={props.formOpen}
      onHiding={OnClickCancelar}
      dragEnabled={true}
      closeOnOutsideClick={false}
      showCloseButton={true}
      showTitle={true}
      title={'Atividade: ' + props.formTitle}
      container=".dx-viewport"
      width={'80vw'}
    //height={'80vh'}
    >

      <ScrollView width='100%' height='100%'>
        {props.children}
        <div className={"col-12 p-1 m-2 alert alert-danger " + props.errorMessageVisible}> <strong>Verifique os campos! </strong> {props.errorMessage}</div>
        <div className="col-12 p-0 m-0">
          <div className="d-flex justify-content-end">
            <div className="pe-3"><button type="button" className="btn btn-success" onClick={OnClickSalvar}>Salvar</button></div>
            <div className=""><button type="button" className="btn btn-danger" onClick={OnClickCancelar} >Cancelar</button></div>
          </div>
        </div>
      </ScrollView>
    </Popup>
  );
}