import React, { useState, useEffect } from 'react';

import AcompanhamentoList from './AcompanhamentoList'

import Form from './form'

import DataGrid, { LoadPanel, Toolbar, Pager, Paging, ColumnChooser, Summary, GroupItem, Grouping, GroupPanel, Column, Selection, Scrolling, Popup, Lookup, HeaderFilter, SearchPanel } from 'devextreme-react/data-grid';
import 'devextreme-react/text-area';

import { dxCustomStore, getDataSource } from '../../api/dataSource'
import Main from '../../components/main/Main'
import { InputSelect } from '../../components/Layout/Inputs/Select'

import { useAuth } from '../../contexts/auth'
import { GetPolosAccess } from '../../config/user_profile'

const URL = '/customer_success/list'

export default (props) => {
    const { user } = useAuth()
    const [gridDataRows, setGridDataRows] = useState(null)
    const [semestreValue, setSemestreValue] = useState('')
    const [semestreData, setSemestreData] = useState([])
    const [formOpen, setFormOpen] = useState(false)
    const [matriculaId, setMatriculaId] = useState('-1')

    const [polosValue, setPolosValue] = useState([])
    const [polosData, setPolosData] = useState([])




    const polosOnChange = (value) => upDateDataGridRows(value, semestreValue.nome)
    const semestreOnChange = (value) => upDateDataGridRows(polosValue, value.nome)

    useEffect(() => {

        const load = async () => {
            const _polos = await GetPolosAccess('customer_success_acompanhamento', user.id)
            setPolosData(_polos)
            const anosSemestres = await getDataSource('/academico/relacao_aluno_matriculado' + '/list-ano-semestre', 'GET')
            setSemestreData(anosSemestres.data)
            setSemestreValue(anosSemestres.data[0])
        }
        load()





        // dxCustomStore('/academico/relacao_aluno_matriculado/list-ano-semestre').load().done(result => {
        //     setSemestreData(result)
        //     setSemestreValue(result[0])
        // })
    }, []);


    const upDateDataGridRows = async (polo, semestre) => setGridDataRows(dxCustomStore(URL, { polo: polo, semestre: semestre }))

    const renderBtnEdit = (cellData) => {
        return (
            <button className="m-0 p-0 btn btn-sm" type="button" onClick={async () => {
                console.log(cellData)
                // setFormLeadId(cellData.value)
                setMatriculaId(cellData.data.matricula_id)
                setFormOpen(true)
            }}>
                <span><i className="m-0 p-0 me-1 fas fa-pen"></i>{cellData.value}</span>
            </button>
        )
    }



    const renderStatusSimNao = (status) => {
        if (status == 'SIM') return <i className="text-success p-0 fas fa-check fw-bold"></i>
        return <i className="text-danger p-0 fas fa-times fw-bold"></i>
    }


    const renderCsAtividadesEmDia = (cellData) => renderStatusSimNao(cellData.row.data.cs_atividades_em_dia_label)
    const renderCsFinanceiroEmDia = (cellData) => renderStatusSimNao(cellData.row.data.cs_financeiro_em_dia_label)
    const renderCsContatoRealizado = (cellData) => renderStatusSimNao(cellData.row.data.cs_contato_realizado_label)

    const renderAcompanhamento = (cellData) => {
        const quantidade = cellData.text
        if (quantidade == 0) return <span className="text-danger">{quantidade}</span>
        return <span>{quantidade}</span>
    }

    const renderCsAtendimentoNome = (cellData) => {
        const status = cellData.row.data.cs_atendimento_id || 0
        if (status == 1) return <i className="text-success p-0 fas fa-check fw-bold"></i>
        return <i className="text-danger p-0 fas fa-times fw-bold"></i>
    }


    const headerCellRender = (cellData) => <span className="fw-bold">{cellData.column.caption}</span>

    return (<Main title={'/  Pós Vendas/Customer Success'} privileges={props.privileges}>
        <div className="row pb-2">
            <div className="col-sm-4"><InputSelect caption={'Polo'} data={polosData} value={polosValue} setValue={setPolosValue} onChange={polosOnChange} isMulti={false} /></div>
            <div className="col-sm-2"><InputSelect caption={'Semestre'} data={semestreData} value={semestreValue} setValue={setSemestreValue} onChange={semestreOnChange} /></div>
        </div>

        <DataGrid
            id="grid"
            showBorders={true}
            dataSource={gridDataRows}
            repaintChangesOnly={false}
            columnAutoWidth={true}
            allowColumnResizing={true}
            allowColumnReordering={true}
            height="70vh"
            width="100%"
            hoverStateEnabled={true}
        >
            <ColumnChooser enabled={true} height={"90vh"} width={300} allowSearch={true} />
            <Grouping autoExpandAll={false} />
            <GroupPanel visible={true} />
            <Paging defaultPageSize={1000} />
            <Pager showPageSizeSelector={true} allowedPageSizes={[50, 100, 250, 500]} showInfo={true} />
            <HeaderFilter visible={true} />
            <SearchPanel visible={true} width={240} placeholder="Pesquisar..." />
            <Scrolling mode="standard" useNativ={true} showScrollbar="always" />
            <Selection mode="single" />
            <Column caption="Alunos Matriculados" alignment="center" headerCellRender={headerCellRender}>
                <Column
                    allowReordering={false} allowResizing={false} allowFiltering={false} allowSorting={false} allowGrouping={false}
                    dataField="id" width="50" caption="" visible={true} alignment="center" cellRender={renderBtnEdit} />
                <Column dataField="aluno_nome" caption="Nome" visible={true} />
                <Column dataField="matricula_rgm" caption="RGM" visible={true} />
                <Column dataField="aluno_cpf" caption="CPF" visible={true} />

                <Column dataField="aluno_municipio_uf" caption="Município" visible={true} />                
                
            </Column>
            <Column caption="Acompanhamento" alignment="center" headerCellRender={headerCellRender}>


                {/* cs_atividades_em_dia_value */}

                <Column dataField="cs_atendimento_nome" cellRender={renderCsAtendimentoNome} caption="Atendimento" alignment="center" visible={true} width="100" />
                <Column dataField="cs_quantidade_acompanhamento" cellRender={renderAcompanhamento} caption="Qtd" alignment="center" visible={true} width="70" />
                <Column dataField="cs_ultimo_acompanhamento_data" caption="Último" visible={true} dataType={"date"} alignment="center" width="100" />
                <Column dataField="cs_proximo_acompanhamento_data" caption="Próximo" alignment="center" dataType={"date"} visible={true} width="100" />

                <Column dataField="cs_atividades_em_dia_label" cellRender={renderCsAtividadesEmDia} caption="Atividades" alignment="center" visible={true} width="100" />
                <Column dataField="cs_financeiro_em_dia_label" cellRender={renderCsFinanceiroEmDia} caption="Financeiro" alignment="center" visible={true} width="100" />
                <Column dataField="cs_contato_realizado_label" cellRender={renderCsContatoRealizado} caption="Retorno" alignment="center" visible={true} width="100" />







            </Column>
            <Column caption="Matrícula" alignment="center" headerCellRender={headerCellRender}>
                <Column dataField="curso_grau_nome" caption="Grau" visible={true} width="100" />
                <Column dataField="curso_nome" caption="Curso" visible={true} width="200" />
                <Column dataField="data_matricula" caption="Data" visible={true} dataType={"date"} alignment="center" width="100" />
                <Column dataField="matricula_ingresso" caption="Ingresso" visible={true} alignment="center" width="100" />
                <Column dataField="matricula_serie" caption="Série" visible={true} alignment="center" width="100" />
            </Column>
            <Summary><GroupItem column="id" displayFormat="{0}" summaryType="count" /></Summary>
        </DataGrid>


        <Form
            formOpen={formOpen}
            setFormOpen={setFormOpen}
            matriculaId={matriculaId}
            upDateDataGridRows={upDateDataGridRows}
            polo={polosValue}
            semestre={semestreValue.nome}
        />

        {/* <span>Incluir opção para classificar o nível de satisfação?</span> */}

    </Main >
    )
}