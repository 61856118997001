import React, { useState, useEffect } from 'react';
import { dxCustomStore } from '../../api/dataSource'
import DataGrid, { LoadPanel, Pager, Paging, Summary, GroupItem, Column, Selection, Scrolling, Lookup, HeaderFilter, SearchPanel } from 'devextreme-react/data-grid'


export default (props) => {
    return <DataGrid className=""
        id="gridacompanhamentos"
        showBorders={true}
        dataSource={props.data}
        repaintChangesOnly={true}
        columnAutoWidth={true}
        allowColumnResizing={false}
        allowColumnReordering={false}
        height="150"
        width="100%"
        columnWidth={'auto'}

        onCellClick={ (ev) => {
            console.log(ev)
        }}

    >
        <Scrolling mode="standard" useNativ={true} showScrollbar="always" />
        <Selection mode="single" />
        <Column dataField="id" caption="ID" visible={false} />
        <Column dataField="data_hora" width="120" caption="Inclusão" visible={true} dataType={"datetime"} />        
        

        <Column dataField="observacao_consultor" caption="Observação Consultor" visible={true} width="200" />
        <Column dataField="observacao_aluno" caption="Observação Aluno" visible={true} width="200" />

        <Column dataField="atividades_em_dia" alignment={'center'} caption="Ativ. Em Dia" visible={true} width="60" />
        <Column dataField="financeiro_em_dia" alignment={'center'} caption="Fin. Em Dia" visible={true} width="60" />
        <Column dataField="contato_realizado" alignment={'center'} caption="Cont. Real." visible={true} width="60" />

        <Column dataField="consultor_nome" visible={true} caption="Consultor" />

        <Summary><GroupItem column="id" displayFormat="{0}" summaryType="count" /></Summary>
    </DataGrid>

}