import React, { useState, useEffect } from 'react'
import { Popup } from 'devextreme-react/popup';

import ScrollView from 'devextreme-react/scroll-view';
// import { Item, ButtonItem, ButtonOptions, EmptyItem, SimpleItem, Label } from 'devextreme-react/form';
// import DataGrid, { LoadPanel, Pager, Paging, Summary, GroupItem, Column, Selection, Scrolling, Lookup, HeaderFilter, SearchPanel } from 'devextreme-react/data-grid'
// import { SelectBox } from 'devextreme-react/select-box';

import { useAuth } from '../../contexts/auth';
// import { getAccess } from '../../../config/user_profile'
import { dxCustomStore } from '../../api/dataSource'
import { Button } from 'devextreme-react/button';
import TextBox from 'devextreme-react/text-box';

// import AlunoDetails from '../../../components/aluno/AlunoDetails'
import MatriculaView from '../../components/matricula/MatriculaView'
import AcompanhamentoList from './AcompanhamentoList'



export default (props) => {
    const { user } = useAuth()
    //const user = [] // TEMP
    const [acompanhamentosData, setAcompanhamentosData] = useState([])
    const [acompanhamentoValue, setAcompanhamentoValue] = useState('')
    const [inscricaoStatusIdValue, setInscricaoStatusIdValue] = useState()
    const [consultorIdValue, setConsultorIdValue] = useState()
    const [inscricaoStatusData, setInscricaoStatusData] = useState([])   

    const OnClickCancelar = () => props.setFormOpen(false)

    const OnClickSalvar = async function () {
        let loadPromise

        const body = {
            matricula_id: props.matriculaId,
            acompanhamentos: acompanhamentosData
        }

        const dxCustomStoreLead = dxCustomStore('/customer_success/save')
        loadPromise = dxCustomStoreLead.insert(body)
        loadPromise.done(doneCallback => {
            console.log('save')
            props.upDateDataGridRows(props.polo, props.semestre)
            props.setFormOpen(false)
        })
    }

    const resetForm = () => {
        setConsultorIdValue(null)
        setInscricaoStatusIdValue(null)
        setAcompanhamentoValue('')
        setAcompanhamentosData()
    }



    const AddComp = (e) => {
        e.preventDefault();

        var acompanhamento_observacao_consultor = document.getElementById("acompanhamento_observacao_consultor").value;
        var acompanhamento_observacao_aluno = document.getElementById("acompanhamento_observacao_aluno").value;
        var atividades_em_dia = document.getElementById("atividades_em_dia").value;
        var financeiro_em_dia = document.getElementById("financeiro_em_dia").value;
        var contato_realizado = document.getElementById("contato_realizado").value;

        if (atividades_em_dia == 'Selecione') {
            alert('Selecione atividades em dia Sim ou Não')
            return
        }

        if (financeiro_em_dia == 'Selecione') {
            alert('Selecione financeiro em dia Sim ou Não')
            return
        }
        if (contato_realizado == 'Selecione') {
            alert('Selecione contato realizado Sim ou Não')
            return
        }

        if (!acompanhamento_observacao_consultor) {
            alert('Preencha o campo observações do consultor')
            return
        }

        if (!acompanhamento_observacao_aluno) {
            alert('Preencha o campo observações do aluno')
            return
        }



        let acompanhamentosLoad = []
        acompanhamentosLoad.push({
            id: 0,
            matricula_id: props.matriculaId,
            data_hora: new Date(),
            consultor_id: user.id,
            consultor_nome: user.full_name,
            atividades_em_dia: atividades_em_dia,
            financeiro_em_dia: financeiro_em_dia,
            contato_realizado: contato_realizado,
            observacao_consultor: acompanhamento_observacao_consultor,
            observacao_aluno: acompanhamento_observacao_aluno
        })

        acompanhamentosLoad.concat(acompanhamentosData)
        setAcompanhamentosData(acompanhamentosLoad.concat(acompanhamentosData))

        document.getElementById("acompanhamento_observacao_consultor").value = ''
        document.getElementById("acompanhamento_observacao_aluno").value = ''
        document.getElementById("atividades_em_dia").value = 'Selecione'
        document.getElementById("financeiro_em_dia").value = 'Selecione'
        document.getElementById("contato_realizado").value = 'Selecione'


    }

    return (
        <Popup
            visible={props.formOpen}
            onHiding={OnClickCancelar}
            dragEnabled={true}
            closeOnOutsideClick={false}
            showCloseButton={true}
            showTitle={true}
            title={'Pós Vendas/Customer Success'}
            container=".dx-viewport"
            width={'85vw'}
            onShowing={async () => {
                const body = { matricula_id: props.matriculaId, }

                const dxDataSourceLead = dxCustomStore('/customer_success/list-acompanhamento', body)
                var loadPromise = dxDataSourceLead.load()
                loadPromise.done((result) => {
                    const acompanhamentos = result
                    console.log(acompanhamentos)
                    setAcompanhamentosData(acompanhamentos)
                })


            }}
        >
            <ScrollView width='100%' height='100%'>
                <MatriculaView matriculaId={props.matriculaId} />

                {/* Acompanhamentos */}

                <span className={'h6'} >Acompanhamentos</span>
                <div className="container-fluid alert alert-success p-2">
                    <form onSubmit={AddComp}>

                        <div className="row">
                            <div className="col">
                                <label className="fw-bold">Observações do consultor:</label>
                                <textarea className="form-control" rows="2" id="acompanhamento_observacao_consultor" name="acompanhamento_observacao_consultor"></textarea>
                            </div>
                            <div className="col">
                                <label className="fw-bold">Observações do aluno:</label>
                                <textarea className="form-control" rows="2" id="acompanhamento_observacao_aluno" name="acompanhamento_observacao_aluno"></textarea>
                            </div>
                        </div>





                        <div className="row mt-2 mb-2">
                            <div className="col-sm-3">
                                <div className="input-group mb-3 input-group-sm">
                                    <span className="input-group-text">Atividades em dia</span>
                                    <select className="form-select" id='atividades_em_dia'>
                                        <option disabled selected>Selecione</option>
                                        <option>Sim</option>
                                        <option>Não</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-sm-3">
                                <div className="input-group mb-3 input-group-sm">
                                    <span className="input-group-text">Financeiro em dia</span>
                                    <select className="form-select" id='financeiro_em_dia'>
                                        <option disabled selected>Selecione</option>
                                        <option>Sim</option>
                                        <option>Não</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-sm-3">
                                <div className="input-group mb-3 input-group-sm">
                                    <span className="input-group-text">Contato realizado (retorno)</span>
                                    <select className="form-select" id='contato_realizado'>
                                        <option disabled selected>Selecione</option>
                                        <option>Sim</option>
                                        <option>Não</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-sm-3">
                                <input type="submit" value="Incluir" className="btn btn-primary" />
                            </div>
                        </div>
                    </form>
                    <AcompanhamentoList data={acompanhamentosData} />
                </div>






                <div className="d-flex bd-highlight">
                    <div className="p-2 bd-highlight"><button type="button" className="btn btn-success" onClick={OnClickSalvar}>Salvar</button></div>
                    <div className="p-2 bd-highlight"><button type="button" className="btn btn-danger" onClick={OnClickCancelar} >Cancelar</button></div>
                </div>

            </ScrollView>
        </Popup >
    )
}