import React, { useState, useEffect } from 'react'
import { Popup } from 'devextreme-react/popup';
import ScrollView from 'devextreme-react/scroll-view';
import Form, { Item, ButtonItem, ButtonOptions, EmptyItem, SimpleItem, Label } from 'devextreme-react/form';
import DataGrid, { LoadPanel, Pager, Paging, Summary, GroupItem, Column, Selection, Scrolling, Lookup, HeaderFilter, SearchPanel } from 'devextreme-react/data-grid'
import { SelectBox } from 'devextreme-react/select-box';
import './lead-form.css'
import { useAuth } from '../../../contexts/auth';
import { getAccess } from '../../../config/user_profile'
import { postData, dxCustomStore } from '../../../api/dataSource'
import { Button } from 'devextreme-react/button';
import TextBox from 'devextreme-react/text-box';



export default (props) => {
  const [situacaoData, setSituacaoData] = useState([])
  const [origemData, setOrigemData] = useState([])
  useEffect(() => {
    const load = async () => {
      dxCustomStore('/comercial/lead-situacao').load().done(result => setSituacaoData(result))
      dxCustomStore('/comercial/lead-origem').load().done(result => setOrigemData(result))
    }
    load()

  }, []);


  const { user } = useAuth()
  const [errorMessage, setErrorMessage] = useState('')
  const [errorMessageVisible, setErrorMessageVisible] = useState('invisible')
  const [formData, setFormData] = useState([])
  const [formTitle, setFormTitle] = useState()
  const [acompanhamentosData, setAcompanhamentosData] = useState([])
  const [acompanhamentoValue, setAcompanhamentoValue] = useState('')

  // State Fields Values
  const [poloValue, setPoloValue] = useState()
  const [consultorValue, setConsultorValue] = useState()
  const [origemValue, setOrigemValue] = useState()
  const [situacaoValue, setSituacaoValue] = useState()

  // State Permissões
  const [liberadoGestorDisabled, setLiberadoGestorDisabled] = useState(true)

  // State Fields Data
  const [consultoresData, setConsultoresData] = useState()

  const OnClickCancelar = function () {
    props.setFormOpen(false)
  }

  const OnClickSalvar = async function () {
    let isEdited = false
    const leadId = props.leadId
    if (leadId > 0) isEdited = true
    
    let loadPromise
    // Inclusão.
    if (isEdited == false) {
      const dxCustomStoreLead = dxCustomStore('/comercial/lead/add')
      loadPromise = dxCustomStoreLead.insert(formData)
    }

    // Edição.
    if (isEdited == true) {
      const dxCustomStoreLead = dxCustomStore('/comercial/lead/edit')
      loadPromise = dxCustomStoreLead.update(leadId, formData)
    }

    loadPromise.done(doneCallback => {
      props.refreshDataGrid()
      props.setFormOpen(false)
    }).fail(failCallback => {
      
      console.log(failCallback)

      setErrorMessageVisible('')
      setErrorMessage(failCallback.message)
      console.log(failCallback.message)
    })
  }


  const OnChangeFormData = () => updateFormData({ hasupdates: 1 })
  const updateFormData = (valuesToUpdated) => setFormData(Object.assign({}, formData, valuesToUpdated))

  const getPermissions = async (poloId) => {
    if (await getAccess(user.id, poloId, 'comercial_leads', 'liberado_gestor')) setLiberadoGestorDisabled(false)
  }


  const OnClickIncluirAcompanhamento = () => {
    const acompanhamento = acompanhamentoValue.toString().trim()
    if (acompanhamento) {
      updateFormData({ 'hasupdates': 1 })
      let acompanhamentosLoad = []
      acompanhamentosLoad.push({
        id: 0,
        lead_id: 0,
        data_hora: new Date(),
        consultor_id: user.id,
        consultor_nome: user.full_name,
        descricao: acompanhamento
      })

      acompanhamentosLoad.concat(acompanhamentosData)
      setAcompanhamentosData(acompanhamentosLoad.concat(acompanhamentosData))
      setFormData(Object.assign({}, formData, { acompanhamentos: acompanhamentosLoad.concat(acompanhamentosData) }))
    }
    setAcompanhamentoValue('')
  }


  const updateConsultoresData = (poloId, consultorId = 0) => {
    const dxDataSourceLead = dxCustomStore('/comercial/lead-consultor/' + poloId)
    var loadPromise = dxDataSourceLead.load();
    loadPromise.done(function (resultConsultores) {
      setConsultoresData(resultConsultores)
      if (consultorId > 0) setConsultorValue(consultorId)
    })
  }

  const resetForm = () => {
    setErrorMessage('')
    setErrorMessageVisible('invisible')
    setFormData({})
    setPoloValue(null)
    setConsultorValue(null)
    setOrigemValue(null)
    setSituacaoValue(null)
    setAcompanhamentosData([])
  }

  return (
    <Popup
      visible={props.formOpen}
      onHiding={OnClickCancelar}
      dragEnabled={true}
      closeOnOutsideClick={false}
      showCloseButton={true}
      showTitle={true}
      title={'Lead: ' + formTitle}
      container=".dx-viewport"
      width={'80vw'}
      // height={'40vh'}

      // Load Lead
      onShowing={async () => {
        let isEdited = false
        const leadId = props.leadId

        if (leadId > 0) isEdited = true

        if (isEdited == false) {
          resetForm()
          setFormTitle('Inclusão')
        }


        if (isEdited == true) {
          setErrorMessage('')
          setErrorMessageVisible('invisible')
          setFormTitle(`edição (cód. ${leadId})`)
          const dxDataSourceLead = dxCustomStore('/comercial/lead/load/' + leadId)
          var loadPromise = dxDataSourceLead.load()
          loadPromise.done((resultLead) => {
            const lead = resultLead[0]
            console.log(lead)
            setFormData(lead)
            setPoloValue(lead.polo_id)
            updateConsultoresData(lead.polo_id, lead.consultor_responsavel_id)
            setOrigemValue(lead.origem_id)
            setSituacaoValue(lead.situacao_id)
            setAcompanhamentosData(lead.acompanhamentos)
          })
        }
      }}
    >
      <ScrollView width='100%' height='100%'>
        <Form
          className="mt-2"
          colCount={12}
          formData={formData}
          onFieldDataChanged={OnChangeFormData}          
          labelLocation='top'
          labelMode='static'
          showColonAfterLabel={false}
        >
          <Item colSpan={3} dataField="nome" label={{ text: 'Nome' }} isRequired={true} />
          <Item colSpan={3} dataField="curso_area_interesse" label={{ text: 'Curso/Área de interesse' }} isRequired={true} />
          <Item colSpan={3} dataField="telefone" label={{ text: 'Telefone' }} isRequired={true} editorOptions={{ mask: '(00) X 0000-0000', maskRules: { X: /[9]/ } }} />
          <Item colSpan={3} dataField="email" label={{ text: 'E-mail' }} />

          <Item colSpan={3} dataField="municipio" label={{ text: 'Município' }} isRequired={true} />
          <Item colSpan={3} dataField="polo_id" editorType="dxSelectBox" label={{ text: 'Polo' }} isRequired={true}>
            <SelectBox
              dataSource={props.polos}
              valueExpr="id"
              displayExpr="nome"
              onValueChanged={(e) => {
                setPoloValue(e.value)
                updateFormData({ 'polo_id': e.value, 'hasupdates': 1 })
                updateConsultoresData(e.value)
              }}
              value={poloValue}
            />
          </Item>
          <Item colSpan={3} dataField="consultor_responsavel_id" editorType="dxSelectBox" label={{ text: 'Consultor' }} isRequired={true}>
            <SelectBox
              dataSource={consultoresData}
              valueExpr="id"
              displayExpr="full_name"
              onValueChanged={(e) => {
                setConsultorValue(e.value)
                updateFormData({ 'consultor_responsavel_id': e.value, 'hasupdates': 1 })
              }}
              value={consultorValue}
            />
          </Item>
          <Item colSpan={3} dataField="origem_id" editorType="dxSelectBox" label={{ text: 'Origem' }} isRequired={true}>
            <SelectBox
              dataSource={origemData}
              valueExpr="id"
              displayExpr="nome"
              onValueChanged={(e) => {
                setOrigemValue(e.value)
                updateFormData({ 'origem_id': e.value, 'hasupdates': 1 })
              }}
              value={origemValue}
            />
          </Item>

          <Item colSpan={3} dataField="situacao_id" editorType="dxSelectBox" label={{ text: 'Situação' }} isRequired={true}>
            <SelectBox
              dataSource={situacaoData}
              valueExpr="id"
              displayExpr="nome"
              onValueChanged={(e) => {
                setSituacaoValue(e.value)
                updateFormData({ 'situacao_id': e.value, 'hasupdates': 1 })
              }}
              value={situacaoValue}
            />
          </Item>
          <Item colSpan={3} dataField="proximo_contato" editorType="dxDateBox" label={{ text: 'Próximo contato' }} />



          <Item colSpan={3} dataField="nao_deu_retorno_nao_responde" label={{ text: 'Situação/Complemento' }}
            editorType="dxCheckBox"
            editorOptions={
              {
                text: 'Não responde/Não deu retorno'
              }
            }>
            {/* <Label visible={false} /> */}
          </Item>


          {/* <EmptyItem colSpan={3} /> */}
          <EmptyItem colSpan={3} />
        </Form>

        <Item colSpan={10} dataField="acompanhamento" />


        <ButtonItem horizontalAlignment="left" colSpan={2} verticalAlignment={'bottom'} >
          <ButtonOptions
            text="Incluir"
            type='default'
            onClick={OnClickIncluirAcompanhamento}
          />
        </ButtonItem>

        {/* Acompanhamentos */}
        <div className={'pt-4'}>Acompanhamentos</div>
        <div className="d-flex pb-2">
          <TextBox
            className={'me-2'}
            width={'100%'}
            placeholder="Escreva aqui uma atualização do atendimento"
            onValueChanged={(e) => {
              setAcompanhamentoValue(e.value)
              updateFormData({ 'hasupdates': 1 })
            }}
            value={acompanhamentoValue}
          />
          <Button width={120} text="Incluir" type='default' onClick={OnClickIncluirAcompanhamento} />
        </div>
        <DataGrid className=""
          id="gridacompanhamentos"
          showBorders={true}
          dataSource={acompanhamentosData}
          repaintChangesOnly={true}
          columnAutoWidth={true}
          allowColumnResizing={false}
          allowColumnReordering={false}
          height="200"
          width="100%"
          columnWidth={'auto'}
        >
          <Scrolling mode="standard" useNativ={true} showScrollbar="always" />
          <Selection mode="single" />
          <Column dataField="id" caption="ID" visible={false} />
          <Column dataField="data_hora" caption="Inclusão" visible={true} dataType={"datetime"} />
          <Column dataField="consultor_nome" visible={true} caption="Consultor" />
          {/* //<Column dataField="consultor_responsavel_id" visible={true} caption="Consultor"><Lookup dataSource={dxCustomStore('/sys/user/list')} displayExpr="full_name" valueExpr="id" /></Column> */}
          <Column dataField="descricao" caption="Acompanhamento" visible={true} width="200" />
          <Summary><GroupItem column="id" displayFormat="{0}" summaryType="count" /></Summary>
        </DataGrid>


        {/* <Item colSpan={3} dataField="liberado_gestor"
            editorType="dxCheckBox"
            editorOptions={{
              disabled: liberadoGestorDisabled,
              text: 'Liberado pelo gestor',
            }}>
            <Label visible={false} />
          </Item> */}


        <div className={"col-12 p-1 m-2 alert alert-danger " + errorMessageVisible}> <strong>Verifique os campos! </strong> {errorMessage}</div>
        <div className="d-flex bd-highlight">
          <div className="p-2 flex-grow-1 bd-highlight">
            <a href={"https://wa.me/55" + formData.telefone} target="_blank">WhatsApp Web - Abrir conversa</a>
          </div>
          <div className="p-2 bd-highlight"><button type="button" className="btn btn-success" onClick={OnClickSalvar}>Salvar</button></div>
          <div className="p-2 bd-highlight"><button type="button" className="btn btn-danger" onClick={OnClickCancelar} >Cancelar</button></div>
        </div>

      </ScrollView>
    </Popup >
  )
}