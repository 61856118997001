import React, { useState, useEffect } from 'react'
import { Popup } from 'devextreme-react/popup';

import ScrollView from 'devextreme-react/scroll-view';
import Form, { Item, ButtonItem, ButtonOptions, EmptyItem, SimpleItem, Label } from 'devextreme-react/form';
import DataGrid, { LoadPanel, Pager, Paging, Summary, GroupItem, Column, Selection, Scrolling, Lookup, HeaderFilter, SearchPanel } from 'devextreme-react/data-grid'
import { SelectBox } from 'devextreme-react/select-box';
import './form.css'
import { useAuth } from '../../../contexts/auth';
import { getAccess } from '../../../config/user_profile'
import { getDataSourceByPost, dxCustomStore } from '../../../api/dataSource'
import { Button } from 'devextreme-react/button';
import TextBox from 'devextreme-react/text-box';

import AlunoDetails from '../../../components/aluno/AlunoDetails'

const ambientacaoData = [{ id: 0, nome: 'NÃO' }, { id: 1, nome: 'SIM' }]


export default (props) => {
  const { user } = useAuth()

  const [consultorData, setConsultorData] = useState()


  const [ambientacaoAlunoAvisadoValue, setAmbientacaoAlunoAvisadoValue] = useState()
  const [ambientacaoAlunoCienteValue, setAmbientacaoAlunoCienteValue] = useState()
  const [ambientacaoConcluidaValue, setAmbientacaoConcluidaValue] = useState()

  const [acompanhamentosData, setAcompanhamentosData] = useState([])
  const [acompanhamentoValue, setAcompanhamentoValue] = useState('')
  const [inscricaoStatusIdValue, setInscricaoStatusIdValue] = useState()
  const [consultorIdValue, setConsultorIdValue] = useState()
  const [inscricaoStatusData, setInscricaoStatusData] = useState([])

  useEffect(() => {
    const load = async () => {
      const data = await getDataSourceByPost('/gestao/relacao_candidatos_inscritos/status', [])
      setInscricaoStatusData(data.data)
    }
    load()
  }, []);

  const OnClickCancelar = function () {
    resetForm()
    props.upDateDataGridRows(props.polos)
    props.setFormOpen(false)
  }

  const getSituacaoById = (id, data, fieldValue, fieldText) => {
    for (let index = 0; index < data.length; index++) {
      const element = data[index]
      if (element[fieldValue] == id) return element[fieldText]
    }
  }

  const OnClickSalvar = async function () {
    let loadPromise

    const body = {
      inscricao_id: props.candidatoData.id,
      inscricao_status_id: inscricaoStatusIdValue,
      consultor_id: consultorIdValue,
      ambientacao_aluno_avisado: ambientacaoAlunoAvisadoValue,
      ambientacao_aluno_ciente: ambientacaoAlunoCienteValue,
      ambientacao_concluida: ambientacaoConcluidaValue,
      acompanhamentos: acompanhamentosData
    }

    const dxCustomStoreLead = dxCustomStore('/gestao/relacao_candidatos_inscritos/save')
    loadPromise = dxCustomStoreLead.insert(body)
    loadPromise.done(doneCallback => {
      console.log('save')
      props.upDateDataGridRows(props.polos)
      props.setFormOpen(false)
    })
  }

  const IncluirAcompanhamento = (registro) => {
    registro = registro.trim()
    if (registro) {
      let acompanhamentosLoad = []
      acompanhamentosLoad.push({
        id: 0,
        lead_id: 0,
        data_hora: new Date(),
        consultor_id: user.id,
        consultor_nome: user.full_name,
        descricao: registro
      })

      acompanhamentosLoad.concat(acompanhamentosData)
      setAcompanhamentosData(acompanhamentosLoad.concat(acompanhamentosData))
    }
  }

  const OnClickIncluirAcompanhamento = () => {
    const acompanhamento = acompanhamentoValue.toString().trim()
    if (acompanhamento) {
      let acompanhamentosLoad = []
      acompanhamentosLoad.push({
        id: 0,
        lead_id: 0,
        data_hora: new Date(),
        consultor_id: user.id,
        consultor_nome: user.full_name,
        descricao: acompanhamento
      })

      acompanhamentosLoad.concat(acompanhamentosData)
      setAcompanhamentosData(acompanhamentosLoad.concat(acompanhamentosData))
    }
    setAcompanhamentoValue('')
  }

  const FormatDate = (date) => {
    try {
      return `${date.substring(8, 11)}/${date.substring(5, 7)}/${date.substring(0, 4)}`
    } catch (error) {
      return ''
    }
  }

  const resetForm = () => {
    setConsultorIdValue(null)
    setInscricaoStatusIdValue(null)
    setAcompanhamentoValue('')
    setAcompanhamentosData()

    setAmbientacaoAlunoAvisadoValue(null)
    setAmbientacaoAlunoCienteValue(null)
    setAmbientacaoConcluidaValue(null)
  }

  const BuildField = (caption, value) => {
    return (
      <div className="d-flex flex-column">
        <div className="p-0 fw-bold">{caption}</div>
        <div className="p-0">{value}</div>
      </div>
    )
  }


  return (
    <Popup
      visible={props.formOpen}
      onHiding={OnClickCancelar}
      dragEnabled={true}
      closeOnOutsideClick={false}
      showCloseButton={true}
      showTitle={true}
      title={'Inscrição: ' + props.candidatoData.inscricao + ' Polo: ' + props.candidatoData.polo_nome}
      container=".dx-viewport"
      width={'85vw'}
      onShowing={async () => {
        const poloId = props.candidatoData.polo_id || -1

        const resultConsultor = await getDataSourceByPost('/comercial/relacao_candidatos_inscritos/list-consultor/' + poloId, [])
        setConsultorData(resultConsultor.data)

        const body = {
          consultor_id: props.candidatoData.consultor_id,
          inscricao_status_id: props.candidatoData.inscricao_status_id,
          aluno_inscrito_id: props.candidatoData.id,
        }

        setInscricaoStatusIdValue(props.candidatoData.inscricao_status_id)
        setConsultorIdValue(props.candidatoData.consultor_id)

        setAmbientacaoAlunoAvisadoValue(props.candidatoData.ambientacao_aluno_avisado)
        setAmbientacaoAlunoCienteValue(props.candidatoData.ambientacao_aluno_ciente)
        setAmbientacaoConcluidaValue(props.candidatoData.ambientacao_concluida)




        const dxDataSourceLead = dxCustomStore('/gestao/relacao_candidatos_inscritos/list-acompanhamento', body)
        var loadPromise = dxDataSourceLead.load()
        loadPromise.done((resultLead) => {
          const acompanhamentos = resultLead





          setAcompanhamentosData(acompanhamentos)
        })
      }}
    >
      <ScrollView width='100%' height='100%'>


        <div className=" p-1 border border-dark border-1 mb-2">
          <div className="row">
            <div className="col-sm-3">{BuildField('Nome', props.candidatoData.nome)}</div>
            <div className="col-sm-5">{BuildField('Curso', props.candidatoData.curso)}</div>
            <div className="col-sm-2">{BuildField('Grau', props.candidatoData.curso_grau_nome)}</div>
            <div className="col-sm-2">{BuildField('Município', props.candidatoData.endereco_municipio_uf)}</div>
          </div>


          <div className="row">
            <div className="col-sm-3">{BuildField('Status (SIAA)', props.candidatoData.situacao)}</div>
            <div className="col-sm-3">{BuildField('Data Inscrição', FormatDate(props.candidatoData.data_inscricao))}</div>
            <div className="col-sm-3">{BuildField('Data Matrícula', FormatDate(props.candidatoData.data_matricula))}</div>


          </div>

          <div className="row">
            <div className="col-sm-3">{BuildField('E-mail', props.candidatoData.email)}</div>
            <div className="col-sm-3">{BuildField('Tel. Cel.', props.candidatoData.fone_cel)}</div>
            <div className="col-sm-3">{BuildField('Tel. Res.', props.candidatoData.fone_res)}</div>
            <div className="col-sm-3">{BuildField('Tel. Com.', props.candidatoData.fone_com)}</div>
          </div>

        </div>




        {/* <AlunoDetails
          alunoData={props.alunoData}
        /> */}

        <div className="row">
          <div className="col-sm-3">{BuildField('Status (Sibe)',
            <SelectBox
              dataSource={inscricaoStatusData}
              valueExpr="id"
              displayExpr="nome"
              onValueChanged={async (e) => {
                if (e.value != e.previousValue && e.previousValue > 0) {
                  const oldText = getSituacaoById(e.previousValue, inscricaoStatusData, 'id', 'nome')
                  const newText = getSituacaoById(e.value, inscricaoStatusData, 'id', 'nome')
                  IncluirAcompanhamento('Status alterado: ' + oldText + ' para ' + newText)
                }
                setInscricaoStatusIdValue(e.value)
              }}
              value={inscricaoStatusIdValue}
            />
          )}</div>

          <div className="col-sm-3">{BuildField('Consultor',
            <SelectBox
              dataSource={consultorData}
              valueExpr="id"
              displayExpr="full_name"
              onValueChanged={(e) => {
                if (e.value != e.previousValue) {
                  const oldText = getSituacaoById(e.previousValue, consultorData, 'id', 'full_name')
                  const newText = getSituacaoById(e.value, consultorData, 'id', 'full_name')

                  if (oldText == undefined) {
                    IncluirAcompanhamento('Consultor definido: ' + newText)
                  } else {
                    IncluirAcompanhamento('Consultor alterado: ' + oldText + ' para ' + newText)
                  }
                }
                setConsultorIdValue(e.value)
              }}
              value={consultorIdValue}
            />
          )}</div>




          <div className="col-sm-2">{BuildField('Ambientação Aluno Avisado',
            <SelectBox
              dataSource={ambientacaoData}
              valueExpr="id"
              displayExpr="nome"
              onValueChanged={(e) => {
                if (e.value != e.previousValue) {
                  const oldText = getSituacaoById(e.previousValue, ambientacaoData, 'id', 'nome')
                  const newText = getSituacaoById(e.value, ambientacaoData, 'id', 'nome')

                  if (oldText == undefined) {
                    IncluirAcompanhamento('Ambientação Aluno Avisado definida: ' + newText)
                  } else {
                    IncluirAcompanhamento('Ambientação Aluno Avisado alterada: ' + oldText + ' para ' + newText)
                  }
                }
                setAmbientacaoAlunoAvisadoValue(e.value)
              }}
              value={ambientacaoAlunoAvisadoValue}
            />
          )}</div>

          <div className="col-sm-2">{BuildField('Ambientação Aluno Ciente',
            <SelectBox
              dataSource={ambientacaoData}
              valueExpr="id"
              displayExpr="nome"
              onValueChanged={(e) => {
                if (e.value != e.previousValue) {
                  const oldText = getSituacaoById(e.previousValue, ambientacaoData, 'id', 'nome')
                  const newText = getSituacaoById(e.value, ambientacaoData, 'id', 'nome')

                  if (oldText == undefined) {
                    IncluirAcompanhamento('Ambientação Aluno Ciente definida: ' + newText)
                  } else {
                    IncluirAcompanhamento('Ambientação Aluno Ciente alterada: ' + oldText + ' para ' + newText)
                  }
                }
                setAmbientacaoAlunoCienteValue(e.value)
              }}
              value={ambientacaoAlunoCienteValue}
            />
          )}</div>


          <div className="col-sm-2">{BuildField('Ambientação Concluída',
            <SelectBox
              dataSource={ambientacaoData}
              valueExpr="id"
              displayExpr="nome"
              onValueChanged={(e) => {
                if (e.value != e.previousValue) {
                  const oldText = getSituacaoById(e.previousValue, ambientacaoData, 'id', 'nome')
                  const newText = getSituacaoById(e.value, ambientacaoData, 'id', 'nome')

                  if (oldText == undefined) {
                    IncluirAcompanhamento('Ambientação Concluída definida: ' + newText)
                  } else {
                    IncluirAcompanhamento('Ambientação Concluída alterada: ' + oldText + ' para ' + newText)
                  }
                }
                setAmbientacaoConcluidaValue(e.value)
              }}
              value={ambientacaoConcluidaValue}
            />
          )}</div>

        </div>


        {/* Acompanhamentos */}
        <div className={'pt-4'}>Registros</div>
        <div className="d-flex pb-2">
          <TextBox
            className={'me-2'}
            width={'100%'}
            placeholder="Escreva aqui uma atualização do atendimento"
            onValueChanged={(e) => {
              setAcompanhamentoValue(e.value)
            }}
            value={acompanhamentoValue}
          />
          <Button width={120} text="Incluir" type='default' onClick={OnClickIncluirAcompanhamento} />
        </div>
        <DataGrid className=""
          id="gridacompanhamentos"
          showBorders={true}
          dataSource={acompanhamentosData}
          repaintChangesOnly={true}
          columnAutoWidth={true}
          allowColumnResizing={false}
          allowColumnReordering={false}
          height="150"
          width="100%"
          columnWidth={'auto'}
        >
          <Scrolling mode="standard" useNativ={true} showScrollbar="always" />
          <Selection mode="single" />
          <Column dataField="id" caption="ID" visible={false} />
          <Column dataField="data_hora" width="120" caption="Inclusão" visible={true} dataType={"datetime"} />
          <Column dataField="consultor_nome" visible={true} caption="Consultor" />
          {/* //<Column dataField="consultor_responsavel_id" visible={true} caption="Consultor"><Lookup dataSource={dxCustomStore('/sys/user/list')} displayExpr="full_name" valueExpr="id" /></Column> */}
          <Column dataField="descricao" caption="Acompanhamento" visible={true} width="200" />
          <Summary><GroupItem column="id" displayFormat="{0}" summaryType="count" /></Summary>
        </DataGrid>


        <div className="d-flex bd-highlight">

          <div className="p-2 flex-grow-1 bd-highlight">


            {/* <a href={"https://wa.me/55"} target="_blank">WhatsApp Web - Abrir conversa</a> */}

            {/* <input></input> */}

            <a href={"https://wa.me/55" + String(props.candidatoData.fone_cel).replace(/ /g, "")} target="_blank">WhatsApp Web - Abrir conversa</a>
          </div>

          <div className="p-2 bd-highlight"><button type="button" className="btn btn-success" onClick={OnClickSalvar}>Salvar</button></div>
          <div className="p-2 bd-highlight"><button type="button" className="btn btn-danger" onClick={OnClickCancelar} >Cancelar</button></div>
        </div>

      </ScrollView>
    </Popup >
  )
}