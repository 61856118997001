import React, { useEffect } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import LoadPanel from 'devextreme-react/load-panel'
import { AuthProvider, useAuth } from './contexts/auth'
import Content from './Content'
import { LoginForm } from './components'
import { locale, loadMessages } from "devextreme/localization"
import ptMessages from "devextreme/localization/messages/pt.json"
import OpenChatWhatsApp from './pages/OpenChatWhatsApp'

let dark
const themeStorage = localStorage.getItem('theme') || "generic.light"
if (themeStorage == 'generic.light') dark = false
if (themeStorage == 'generic.dark') dark = true

if (dark) import('./theme_temp/generic-dark-compact-base-common/generic-dark-compact-base-common.css')
if (!dark) import('./theme_temp/generic-light-compact-base-common/generic-light-compact-base-common.css')

//import './theme_temp/generic-light-compact-base-common/generic-light-compact-base-common.css'
//import './theme_temp/generic-dark-compact-base-common/generic-dark-compact-base-common.css'

function Redirect({ to }) {
  let navigate = useNavigate();
  useEffect(() => {
    navigate(to);
  });
  return null;
}

function App() {  
  loadMessages(ptMessages);
  locale('pt')

  const { user, loading } = useAuth();

  if (loading) {
    return <LoadPanel visible={true} />;
  }

  if (user) {
    return <Content />;
  }

  return (
    <Routes>      
      <Route path='/login' element={<LoginForm />} />
      <Route path='/openchatwhatsapp' element={<OpenChatWhatsApp />} />
      <Route path="*" element={<Redirect to="/login" />}/>
      
    </Routes>
  )
}

export default function () {
  return (

    <AuthProvider>
      <App />
    </AuthProvider>

  );
}