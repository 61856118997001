import React, { useState, useEffect } from 'react';
import { Pager, Paging, ColumnChooser, Summary, GroupItem, Grouping, GroupPanel, DataGrid, Column, Selection, Scrolling, Popup, Lookup, HeaderFilter, SearchPanel } from 'devextreme-react/data-grid';
import { useAuth } from '../../../contexts/auth';
import 'devextreme-react/text-area';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add'
import SibeComponents, { ListBox, TextBox, TextBoxRef } from '../../../components/drop-down-box/SibeComponents'
import { postData, getDataSourceByPost, getDataSource } from '../../../api/dataSource'
import Main from '../../../components/main/Main'
import LeadForm from './atividade-form'
import { SelectBox } from 'devextreme-react/select-box';
import { getAccess } from '../../../config/user_profile'
import 'devextreme-react/text-area';

import Form, { Item, ButtonItem, ButtonOptions, EmptyItem, Label } from 'devextreme-react/form';

let sibeComponents = new SibeComponents();

const URL = '/comercial/atividade'

export default function (props) {
  const { user } = useAuth()
  const [loadComplete, setLoadComplete] = useState(false)
  const [phoneMask, setPhoneMask] = useState('(00) 0')
  const [gridDataRows, setGridDataRows] = useState([])
  const [formData, setFormData] = useState([])
  const [formDataAcompanhamento, setFormDataAcompanhamento] = useState([])
  const [formAcompanhamentosData, setFormAcompanhamentosData] = useState([])
  const [formUserData, setFormConsultorData] = useState([])
  const [formTitle, setFormTitle] = useState('')
  const [formId, setFormId] = useState(0)

  const [formErrorMessage, setFormErrorMessage] = useState('')
  const [formErrorMessageVisible, setFormErrorMessageVisible] = useState("invisible")

  const [formOrigemValue, setFormOrigemValue] = useState()
  const [formPoloValue, setFormPoloValue] = useState()
  const [formSituacaoValue, setFormSituacaoValue] = useState()
  const [formRequerenteValue, setFormRequerenteValue] = useState()
  const [formAtribuidoValue, setFormAtribuidoValue] = useState()

  const [gridPoloData, setGridPoloData] = useState([])
  const [gridUserData, setUserData] = useState([])
  const [gridSituacaoData, setGridSituacaoData] = useState([])

  const [polos, setPolos] = useState([])
  const [formOpen, setFormOpen] = useState(false)

  const onPolosValueChange = (value) => {
    setPolos(value)
    if (loadComplete) upDateDataGridRows(value)
  }

  const upDateDataGridRows = async (polos) => {
    const data = await getDataSourceByPost(URL + '/list', { polos: polos })
    setGridDataRows(data.data)
  }

  const [liberadoGestorDisabled, setLiberadoGestorDisabled] = useState(true)
  useEffect(() => {
    const load = async () => {
      setPolos(props.privileges.polos)
      upDateDataGridRows(props.privileges.polos)
      const formConsultorResponse = await getDataSource('/comercial/lead-consultor/0')
      setFormConsultorData(formConsultorResponse.data)
      const gridPolosResponse = await getDataSource('/sys/polo')
      const gridConsultorResponse = await getDataSource('/sys/user')
      const gridSituacoesResponse = await getDataSource('/comercial/atividade-situacao')
      setGridPoloData(gridPolosResponse.data)
      setUserData(gridConsultorResponse.data)

      setGridSituacaoData(gridSituacoesResponse.data)
      setFormConsultorData([])
    }
    load()
    setLoadComplete(true)
  }, []);

  const onRowPrepared = (e) => {
    if (e.rowType == 'data' && e.data.atividade_situacao_id == 1) e.rowElement.id = 'atividade-planejada'
    if (e.rowType == 'data' && e.data.atividade_situacao_id == 2) e.rowElement.id = 'atividade-em-andamento'
    if (e.rowType == 'data' && e.data.atividade_situacao_id == 3) e.rowElement.id = 'atividade-realizada'
    if (e.rowType == 'data' && e.data.atividade_situacao_id == 4) e.rowElement.id = 'atividade-cancelada'
  }

  const formBtnSalvarOnClick = async () => {

    if (formId == 0) console.log('Inclusão!')
    if (formId != 0) console.log('Edição!')

    const atividade = {
      hasupdates: formData.hasupdates,
      nome: formData.nome,



      user_created: user.id,


      liberado_gestor: formData.liberado_gestor,
      proximo_contato: formData.proximo_contato,
      municipio: formData.municipio,


      polo_id: formPoloValue,

      user_id_requerente: formRequerenteValue,
      user_id_atribuido: formAtribuidoValue,

      situacao_id: formSituacaoValue,
      acompanhamentos: formAcompanhamentosData

    }
    console.log(atividade)

    let response
    // Inclusão.
    if (formId == 0) {
      response = await postData('/comercial/atividade/add', atividade)
    }

    // Edição.
    if (formId != 0) {
      response = await postData('/comercial/lead/edit/' + formId, atividade)
    }


    setFormErrorMessage('')
    setFormErrorMessageVisible('invisible')

    if (response.isOk == true) {
      //console.log(response.message)

      upDateDataGridRows(props.privileges.polos)
      setFormOpen(false)

    }

    if (response.isOk == false) {
      console.log(response.message)
      setFormErrorMessage(response.message)
      setFormErrorMessageVisible('visible')
    }




  }




  const onClickIncluir = async () => {
    setFormErrorMessage('')
    setFormErrorMessageVisible('invisible')
    setFormTitle('Inclusão')
    setFormId(0)
    formData.curso_area_interesse = ''
    formData.nome = ''
    formData.telefone = ''
    formData.email = ''
    formData.user_created = user.id

    formData.liberado_gestor = ''
    formData.proximo_contato = null
    formData.municipio = ''

    setFormOrigemValue('')
    setFormPoloValue('')
    setFormSituacaoValue('')
    setFormRequerenteValue('')
    setFormAtribuidoValue('')
    setFormAcompanhamentosData([])

    setFormOpen(true)
  }

  const renderBtnEdit = (cellData) => {
    const id = cellData.value
    return (
      <button className="m-0 p-0 btn btn-sm" type="button" onClick={async () => {
        setFormErrorMessage('')
        setFormErrorMessageVisible('invisible')
        if (await getAccess(user.id, cellData.data.polo_id, 'comercial_atividades', 'liberado_gestor')) setLiberadoGestorDisabled(false)

        setFormTitle('Edição - ID: ' + id)
        setFormId(id)

        formData.hasupdates = 0
        formData.curso_area_interesse = cellData.data.curso_area_interesse
        formData.nome = cellData.data.nome
        formData.telefone = cellData.data.telefone
        formData.email = cellData.data.email


        formData.liberado_gestor = cellData.data.liberado_gestor

        formData.proximo_contato = cellData.data.proximo_contato
        formData.municipio = cellData.data.municipio
        //formData.proximo_contato = cellData.data.proximo_contato,
        //formData.municipio = cellData.data.municipio,


        setFormOrigemValue(cellData.data.origem_id)
        setFormPoloValue(cellData.data.polo_id)
        setFormSituacaoValue(cellData.data.situacao_id)
        await upDateFormUserData(cellData.data.polo_id)
        setFormRequerenteValue(cellData.data.user_id_requerente)
        setFormAtribuidoValue(cellData.data.user_id_atribuido)

        const acompanhamentosResponse = await getDataSource('/comercial/atividade-acompanhamento/list/' + id)
        setFormAcompanhamentosData(acompanhamentosResponse.data)
        setFormOpen(true)
      }}>
        <span><i className="m-0 p-0 me-1 fas fa-pen"></i>{cellData.value}</span>
      </button>
    )
  }

  const upDateFormUserData = async function (poloId) {
    const formUserResponse = await getDataSource('/comercial/lead-consultor/' + poloId)
    setFormConsultorData(formUserResponse.data)
  }

  const OnClickIncluirAcompanhamento = async function () {
    formData.hasupdates = 1
    const acompanhamentoDescricao = formData.acompanhamento

    if (acompanhamentoDescricao) {
      const acompanhamentosLoad = []
      const dataHoraAtual = new Date()
      acompanhamentosLoad.push({
        id: 0,
        lead_id: 0,
        data_hora: dataHoraAtual,
        consultor_id: user.id,
        descricao: acompanhamentoDescricao
      })
      acompanhamentosLoad.concat(formAcompanhamentosData)
      setFormAcompanhamentosData(acompanhamentosLoad.concat(formAcompanhamentosData))

      formData.acompanhamento = ''
    }
  }

  const OnChangeFormData = (e) => {
    formData.hasupdates = 1
  }

  return (<Main title={'/  Comercial  /  Atividades'} privileges={props.privileges}>

    <div className="row p-2">
      <div className="col-4 p-0 m-0">
        <div className="d-flex flex-column ps-3">
          <div className="p-0 m-0 fw-bold">Polos:</div>
          <div className="p-0 m-0">{sibeComponents.SelectMultiple(props.privileges.polos, polos, onPolosValueChange, true)}</div>
        </div>
      </div>

      <div className="col-4"></div>

      <div className="col-4 p-0 m-0">

        <div className="d-flex justify-content-end align-items-center mt-4 me-4 ">
          <Fab onClick={onClickIncluir} className={"px-3"} variant="extended" size="small" color="primary" aria-label="add">
            <AddIcon />Incluir
          </Fab>
        </div>
      </div>
    </div>





    <DataGrid className="mt-2"
      id="grid"
      twoWayBindingEnabled={false}
      showBorders={true}
      dataSource={gridDataRows}
      repaintChangesOnly={false}
      columnAutoWidth={true}
      allowColumnResizing={true}
      allowColumnReordering={true}
      height="80vh"
      width="100%"
      onRowPrepared={onRowPrepared}
    >
      <ColumnChooser enabled={true} height={"90vh"} width={300} allowSearch={true} />
      <Grouping autoExpandAll={false} />
      <GroupPanel visible={true} />
      <Paging defaultPageSize={500} />
      <Pager showPageSizeSelector={true} allowedPageSizes={[50, 100, 250, 500]} showInfo={true} />
      <HeaderFilter visible={true} />
      <SearchPanel visible={true} width={240} placeholder="Pesquisar..." />
      <Scrolling mode="standard" useNativ={true} showScrollbar="always" />

      <Selection mode="single" />
      <Column dataField="id" caption="ID" visible={true} alignment="left" cellRender={renderBtnEdit} />
      <Column dataField="polo_id" visible={true} caption="Polo"><Lookup dataSource={gridPoloData} displayExpr="nome" valueExpr="id" /></Column>
      <Column dataField="data_hora_inclusao" caption="Inclusão" visible={true} dataType={"datetime"} />
      <Column dataField="data_hora_ultimo_acompanhamento" caption="Últ.acomp." visible={true} dataType={"datetime"} defaultSortOrder="desc" />
      <Column dataField="data_planejada" caption="Data Planejada" visible={true} hint="Data Planejada" dataType={"date"} />
      <Column dataField="data_realizada" caption="Data Realizada" visible={true} hint="Data Realizada" dataType={"date"} />
      <Column dataField="user_id_requerente" visible={false} caption="Requerente"><Lookup dataSource={gridUserData} displayExpr="full_name" valueExpr="id" /></Column>
      <Column dataField="user_id_atribuido" visible={true} caption="Atribuído"><Lookup dataSource={gridUserData} displayExpr="full_name" valueExpr="id" /></Column>
      <Column dataField="descricao" caption="Descrição" visible={true} width="300" />
      <Column dataField="conclusao" caption="Conclusão" visible={false} width="300" />
      <Column dataField="atividade_situacao_id" visible={true} caption="Situação"><Lookup dataSource={gridSituacaoData} displayExpr="nome" valueExpr="id" /></Column>
      <Column dataField="liberado_gestor" visible={false} caption="Liberado Gestor"><Lookup dataSource={[{ id: 0, nome: 'Não' }, { id: 1, nome: 'Sim' }]} displayExpr="nome" valueExpr="id" /></Column>
      <Summary><GroupItem column="id" displayFormat="{0}" summaryType="count" /></Summary>
    </DataGrid>


    {/*      FORM      */}
    <LeadForm
      setFormOpen={setFormOpen}
      formOpen={formOpen}
      formBtnSalvarOnClick={formBtnSalvarOnClick}
      formTitle={formTitle}

      errorMessage={formErrorMessage}
      errorMessageVisible={formErrorMessageVisible}
    >


      <Form
        className="mt-2"
        id="form"
        colCount={12}
        formData={formData}
        onFieldDataChanged={OnChangeFormData}
        className=''
        labelLocation='top'
        labelMode='static'
        showColonAfterLabel={false}
      >

        <Item colSpan={3} dataField="polo_id" editorType="dxSelectBox" label={{ text: 'Polo' }} isRequired={true}>
          <SelectBox
            dataSource={props.privileges.polos}
            valueExpr="id"
            displayExpr="nome"
            onValueChanged={(e) => {
              setFormPoloValue(e.value)
              upDateFormUserData(e.value)
              formData.hasupdates = 1
            }}
            value={formPoloValue}
          />
        </Item>

        <Item colSpan={3} dataField="user_id_requerente" editorType="dxSelectBox" label={{ text: 'Requerente' }} isRequired={true}>
          <SelectBox
            dataSource={formUserData}
            valueExpr="id"
            displayExpr="full_name"
            onValueChanged={(e) => {
              setFormRequerenteValue(e.value)
              formData.hasupdates = 1
            }}
            value={formRequerenteValue}
          />
        </Item>

        <Item colSpan={3} dataField="user_id_atribuido" editorType="dxSelectBox" label={{ text: 'Atribuído' }} isRequired={true}>
          <SelectBox
            dataSource={formUserData}
            valueExpr="id"
            displayExpr="full_name"
            onValueChanged={(e) => {
              setFormAtribuidoValue(e.value)
              formData.hasupdates = 1
            }}
            value={formAtribuidoValue}
          />
        </Item>

        <Item colSpan={3} dataField="atividade_situacao_id" editorType="dxSelectBox" label={{ text: 'Situação' }} isRequired={true}>
          <SelectBox
            dataSource={gridSituacaoData}
            valueExpr="id"
            displayExpr="nome"
            onValueChanged={(e) => {
              setFormSituacaoValue(e.value)
              formData.hasupdates = 1
            }}
            value={formSituacaoValue}
          />
        </Item>

        <Item colSpan={3} dataField="data_planejada" editorType="dxDateBox" label={{ text: 'Data Planejada' }} />
        <Item colSpan={3} dataField="data_realizada" editorType="dxDateBox" label={{ text: 'Data Realizada' }} />
        <EmptyItem colSpan={6} />

        <Item dataField="descricao" colSpan={12} editorType="dxTextArea" label={{ text: 'Descrição' }} editorOptions={{ height: 70 }} isRequired={true} />
        <Item dataField="conclusao" colSpan={12} editorType="dxTextArea" label={{ text: 'Conclusão' }} editorOptions={{ height: 70 }} />

      {/* <EmptyItem colSpan={3} /> */}
      {/* <EmptyItem colSpan={3} /> */}



        <Item colSpan={10} dataField="acompanhamento" label={{ text: 'Acompanhamento' }} />

        <ButtonItem horizontalAlignment="left" colSpan={2} verticalAlignment={'bottom'} >
        <ButtonOptions
        text="Incluir"
        type ='default'
        onClick={OnClickIncluirAcompanhamento}
        />
        </ButtonItem>

        <Item colSpan={12}>
        <DataGrid className=""
        id="gridacompanhamentos"
        showBorders={true}
        dataSource={formAcompanhamentosData}
        repaintChangesOnly={true}
        columnAutoWidth={true}
        allowColumnResizing={false}
        allowColumnReordering={false}
        height="200"
        width="100%"
        columnWidth={'auto'}
        >
        <Scrolling mode="standard" useNativ={true} showScrollbar="always" />
        <Selection mode="single" />
        <Column dataField="id" caption="ID" visible={false} />
        <Column dataField="data_hora" caption="Inclusão" visible={true} dataType={"datetime"} />
        <Column dataField="consultor_id" visible={true} caption="Consultor" >
        <Lookup dataSource={gridUserData} displayExpr="full_name" valueExpr="id" />
        </Column>
        <Column dataField="descricao" caption="Acompanhamento" visible={true} width="200" />
        <Summary><GroupItem column="id" displayFormat="{0}" summaryType="count" /></Summary>
        </DataGrid>
        </Item>

      {/* <Item colSpan={3} dataField="liberado_gestor" label={{ text: 'Liberado gestor' }} editorType="dxCheckBox"> */}

        <Item colSpan={3} dataField="liberado_gestor"
        editorType="dxCheckBox"
        editorOptions={{
        disabled: liberadoGestorDisabled,
        text: 'Liberado pelo gestor',
        //value: false,
      }}>
        <Label visible={false} />

        </Item>




      </Form>
    </LeadForm>

  </Main >
  )
}