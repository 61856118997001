import React, { useState, useEffect, useRef } from 'react';
import DataGrid, { LoadPanel, Toolbar, Pager, Paging, ColumnChooser, Summary, GroupItem, Grouping, GroupPanel, Column, Selection, Scrolling, Popup, Lookup, HeaderFilter, SearchPanel } from 'devextreme-react/data-grid';
import { useAuth } from '../../../contexts/auth';
import 'devextreme-react/text-area';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add'
import SibeComponents, { ListBox, TextBox, TextBoxRef } from '../../../components/drop-down-box/SibeComponents'
import { dxCustomStore, getDataSourceByPost } from '../../../api/dataSource'
import Main from '../../../components/main/Main'
import LeadForm from './lead-form'
import LeadDashboard from './lead-dashboard'
import { InputSelect } from '../../../components/Layout/Inputs/Select'
import { GetPolosAccess } from '../../../config/user_profile'
import { RegView } from './RegView'



let sibeComponents = new SibeComponents();

const URL = '/comercial/lead'

export default function (props) {
  const { user } = useAuth()
  const [regViewVisible, setRegViewVisible] = useState(true)

  const [regViewData, setRegViewData] = useState(<div>state</div>)



  const [dataGridState, setDataGridState] = useState(null)
  const [loadComplete, setLoadComplete] = useState(false)
  const [gridDataRows, setGridDataRows] = useState(null)

  const [polosValue, setPolosValue] = useState([])
  const [polosData, setPolosData] = useState([])

  const [formLeadId, setFormLeadId] = useState(-1)
  const [formOpen, setFormOpen] = useState(false)

  const [dashBoardValues, setDashBoardValues] = useState([])

  useEffect(() => {
    const load = async () => {
      const _polos = await GetPolosAccess('comercial_leads', user.id)
      setPolosData(_polos)
    }
    load()
    setLoadComplete(true)
  }, [])

  const UpdateDashBoardValues = async (value) => {
    const resultDashBoardValues = await getDataSourceByPost('/comercial/lead-dashboard', { polos: value })
    setDashBoardValues(resultDashBoardValues.data)
  }

  const onPolosValueChange = async (value) => {
    setPolosValue(value)
    if (loadComplete) {
      upDateDataGridRows(value)
      UpdateDashBoardValues(value)
    }
  }

  const upDateDataGridRows = async (polos) => {
    UpdateDashBoardValues(polos)
    setGridDataRows(dxCustomStore(URL + '/list', { polos: polos }, 'POST'))
  }

  const onRowPrepared = (e) => {

    if (e.rowType == 'data' && e.data.situacao_id == 1) e.rowElement.id = 'lead-prospect'
    if (e.rowType == 'data' && e.data.situacao_id == 2) e.rowElement.id = 'lead-interessado'
    if (e.rowType == 'data' && e.data.situacao_id == 3) e.rowElement.id = 'lead-negociando'
    if (e.rowType == 'data' && e.data.situacao_id == 10) e.rowElement.id = 'lead-nao-deu-retorno-nao-respondeu'
    if (e.rowType == 'data' && e.data.situacao_id == 11) e.rowElement.id = 'lead-interesse-futuro'
    if (e.rowType == 'data' && e.data.situacao_id == 12) e.rowElement.id = 'lead-inscrito-nao-deu-retorno-nao-respondeu'
    if (e.rowType == 'data' && e.data.situacao_id == 97) e.rowElement.id = 'lead-inscrito'
    if (e.rowType == 'data' && e.data.situacao_id == 98) e.rowElement.id = 'lead-matriculado'
    if (e.rowType == 'data' && e.data.situacao_id == 99) e.rowElement.id = 'lead-desistente'
  }


  const onCellClick = async (e) => {
    return
    console.log(e)
    if (e.column.dataField == "registros") {
      const regId = e.data.id
      const acompanhamentoResult = await getDataSourceByPost(`/comercial/lead-acompanhamento/list/${regId}`, {})
      // setDashBoardValues(acompanhamentoResult.data)

      console.log(acompanhamentoResult.data)


      const teste = acompanhamentoResult.data

      const Regs = teste.map((option) => {
        console.log(option.data_hora)

        return <span>{option.data_hora}</span>

        //  console.log(option.data_hora)



      })




      setRegViewData(Regs)

      // )

      setRegViewVisible(true)

      console.log('abrir')
    }
  }


  const renderRegistros = (cellData) => {

    let renderCell = cellData.data.registros
    if (cellData.data.registros == 0) {
      renderCell = <div>
        <span className="text-danger"> {cellData.data.registros} </span>
      </div >
    }
    return renderCell
  }

  const refreshDataGrid = () => dataGridState.instance.refresh()

  const onClickIncluir = async () => {
    setFormLeadId(-1)
    setFormOpen(true)
  }

  const renderBtnEdit = (cellData) => {
    return (
      <button className="m-0 p-0 btn btn-sm" type="button" onClick={async () => {
        setFormLeadId(cellData.value)
        setFormOpen(true)
      }}>
        <span><i className="m-0 p-0 me-1 fas fa-pen"></i>{cellData.value}</span>
      </button>
    )
  }



  return (<Main title={'/  Comercial  /  Leads'} privileges={props.privileges}>


    <div className="row pb-2 align-items-center">
      <div className="col-sm-4">
        <InputSelect caption={'Polo'} data={polosData} value={polosValue} setValue={setPolosValue} onChange={onPolosValueChange} isMulti={true} /></div>
      <div className="col-sm-4">
        <div className="d-flex py-3">

          <a href="/#/comercial/lead/inclusao_lote" className="me-4 pt-2"><i className="fas fa-file-excel me-2"></i>Inclusão por Lote</a>

          <Fab onClick={onClickIncluir} className={""} variant="extended" size="small" color="primary" aria-label="add">
            <AddIcon />Incluir
          </Fab>
        </div>

      </div>


    </div>


    {/* <div className="d-flex my-2 px-3 align-items-center">
      <div className="p-0 bd-highlight fw-bold ">Polos</div>
      <div className="me-auto p-2 bd-highlight">
        {sibeComponents.SelectMultiple(polosData, polosValue, onPolosValueChange, true)}

        <div className="col-sm-4"><InputSelect caption={'Polo'} data={polosData} value={polosValue} setValue={setPolosValue} onChange={onPolosValueChange} isMulti={true} /></div>



      </div>
      <div className="p-2 bd-highlight"><a href="/#/comercial/lead/inclusao_lote" className="me-4"><i className="fas fa-file-excel me-2"></i>Inclusão por Lote</a></div>
      <div className="p-2 bd-highlight"><Fab onClick={onClickIncluir} className={"px-3"} variant="extended" size="small" color="primary" aria-label="add">
        <AddIcon />Incluir
      </Fab></div>
    </div> */}


    <hr className='my-1 p-0' />

    <DataGrid className=""
      id="grid"
      activeStateEnabled={false}
      ref={ref => setDataGridState(ref)}
      showBorders={true}
      dataSource={gridDataRows}
      //remoteOperations={true}
      // hoverStateEnabled={true}
      repaintChangesOnly={false}
      twoWayBindingEnabled={false}
      renderWhen={false}
      columnAutoWidth={true}
      allowColumnResizing={true}
      allowColumnReordering={true}
      height="70vh"
      width="100%"
      onRowPrepared={onRowPrepared}
      onCellClick={onCellClick}
    >
      <ColumnChooser enabled={true} height={"90vh"} width={300} allowSearch={true} />
      <Grouping autoExpandAll={false} />
      <GroupPanel visible={true} />
      <Paging defaultPageSize={50} />
      <Pager showPageSizeSelector={true} allowedPageSizes={[50, 100, 250, 500]} showInfo={true} />
      <HeaderFilter visible={true} />
      <SearchPanel visible={true} width={240} placeholder="Pesquisar..." />
      <Scrolling mode="standard" useNativ={true} showScrollbar="always" />
      {/* <Selection mode="single" /> */}


      <Column dataField="situacao_nome" caption="Situação" groupIndex={0} autoExpandGroup={false} visible={true} />
      <Column dataField="id" caption="ID" visible={true} alignment="center" cellRender={renderBtnEdit} />
      <Column dataField="registros" alignment="center" caption="Registros" visible={true} width="100" cellRender={renderRegistros} />
      <Column dataField="nome" caption="Nome" visible={true} width="200" />
      <Column dataField="data_hora_inclusao" caption="Inclusão" visible={false} dataType={"datetime"} />
      <Column dataField="data_hora_ultimo_acompanhamento" caption="Últ.acomp." visible={true} dataType={"datetime"} defaultSortOrder="desc" />
      <Column dataField="proximo_contato" caption="Próx. contato" visible={true} hint="Próximo Contato" dataType={"date"} />
      <Column dataField="polo_nome" caption="Polo" visible={true} />
      <Column dataField="consultor_responsavel_nome" caption="Consultor" visible={true} />
      <Column dataField="origem_nome" caption="Origem" visible={true} />
      <Column dataField="telefone" caption="Telefone" visible={true} mask="(000) 000-0000" />
      <Column dataField="email" caption="E-mail" visible={false} />
      <Column dataField="municipio" caption="Município" visible={false} />
      <Column dataField="curso_area_interesse" caption="Curso/Área Interesse" visible={true} width="200" />
      <Column dataField="liberado_gestor" visible={false} caption="Liberado Gestor"><Lookup dataSource={[{ id: 0, nome: 'Não' }, { id: 1, nome: 'Sim' }]} displayExpr="nome" valueExpr="id" /></Column>
      <Column dataField="nao_deu_retorno_nao_responde" visible={false} caption="Não deu retorno/Não responde"><Lookup dataSource={[{ id: 0, nome: 'Falso' }, { id: 1, nome: 'Verdadeiro' }]} displayExpr="nome" valueExpr="id" /></Column>
      <Summary><GroupItem column="id" displayFormat="{0}" summaryType="count" /></Summary>
    </DataGrid>

    {/* <RegView visible={true} /> */}
    {/* <RegView
      visible={regViewVisible}
      setVisible={setRegViewVisible}
      data={regViewData}
    /> */}
    <LeadForm setFormOpen={setFormOpen} formOpen={formOpen} leadId={formLeadId} polos={polosData} refreshDataGrid={refreshDataGrid} />



    <LeadDashboard leads={gridDataRows} dashBoardValues={dashBoardValues} />

  </Main >
  )
}