import React from 'react'

export default (props) => {
    
    const BuildField = (caption, value) => {
        return (
            <div>
                <span className="p-0 pe-2 fw-bold">{caption}:</span>
                <span className="p-0">{value}</span>
            </div>
        )
    }

    const FormatDate = (date) => {
        try {
            return `${date.substring(8, 11)}/${date.substring(5, 7)}/${date.substring(0, 4)}`
        } catch (error) {
            return ''
        }
    }

    return (
        <div className=" p-1 border border-dark border-1 mb-2">
            <div className="row">
                <div className="col-sm-4">{BuildField('Nome', props.alunoData.aluno_nome)}</div>
                <div className="col-sm-4">{BuildField('Curso', props.alunoData.curso_nome)}</div>
                <div className="col-sm-2">{BuildField('Grau', props.alunoData.curso_grau_nome)}</div>
                <div className="col-sm-2">{BuildField('RGM', props.alunoData.rgm)}</div>
            </div>

            <div className="row">
                <div className="col-sm-4">{BuildField('Polo', props.alunoData.polo_nome)}</div>
                <div className="col-sm-2">{BuildField('Matrícula', FormatDate(props.alunoData.data_matricula))}</div>
                <div className="col-sm-2">{BuildField('Série', props.alunoData.serie)}</div>
                <div className="col-sm-2">{BuildField('Ingresso', props.alunoData.ingresso_ano_trimestre)}</div>
                <div className="col-sm-2">{BuildField('Semestre', props.alunoData.semestre)}</div>

            </div>
            {/* <hr /> */}

            <div className="row">
                <div className="col-sm-6">{BuildField('Endereço', props.alunoData.aluno_end_endereco)}</div>
                <div className="col-sm-2">{BuildField('Nº', props.alunoData.aluno_end_numero)}</div>
                <div className="col-sm-2">{BuildField('CEP', props.alunoData.aluno_end_cep)}</div>
                <div className="col-sm-2">{BuildField('Comp.', props.alunoData.aluno_end_complemento)}</div>

                <div className="col-sm-4">{BuildField('Bairro', props.alunoData.aluno_end_bairro)}</div>
                <div className="col-sm-6">{BuildField('Município', props.alunoData.aluno_end_municipio)}</div>
                <div className="col-sm-2">{BuildField('UF', props.alunoData.aluno_end_uf)}</div>
            </div>
            {/* <hr /> */}

            <div className="row">
                <div className="col-sm-3">{BuildField('E-mail', props.alunoData.aluno_email)}</div>
                <div className="col-sm-3">{BuildField('Tel. Cel.', props.alunoData.aluno_telefone_celular)}</div>
                <div className="col-sm-3">{BuildField('Tel. Res.', props.alunoData.aluno_telefone_residencial)}</div>
                <div className="col-sm-3">{BuildField('Tel. Com.', props.alunoData.aluno_telefone_comercial)}</div>
            </div>            
        </div>
    )
}