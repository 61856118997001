import React, { useState, useEffect } from 'react';

import DataGrid, { LoadPanel, Toolbar, Pager, Paging, ColumnChooser, Summary, GroupItem, Grouping, GroupPanel, Column, Selection, Scrolling, Popup, Lookup, HeaderFilter, SearchPanel } from 'devextreme-react/data-grid';
import 'devextreme-react/text-area';

import { dxCustomStore, getDataSource } from '../../../api/dataSource'
import Main from '../../../components/main/Main'
import { InputSelect } from '../../../components/Layout/Inputs/Select'

import { GetPolosAccess } from '../../../config/user_profile'
import { useAuth } from '../../../contexts/auth'

const URL = '/comercial/comercial_relatorio_comissao_rematricula'

export default (props) => {
    const { user } = useAuth()
    const [gridDataRows, setGridDataRows] = useState(null)
    const [semestreValue, setSemestreValue] = useState('')
    const [semestreData, setSemestreData] = useState([])
    const [polosValue, setPolosValue] = useState([])
    const [polosData, setPolosData] = useState([])

    const polosOnChange = (value) => upDateDataGridRows(value, semestreValue.nome)
    const semestreOnChange = (value) => upDateDataGridRows(polosValue, value.nome)

    useEffect(() => {

        const load = async () => {
            const _polos = await GetPolosAccess('comercial_relatorio_comissao_rematricula', user.id)
            setPolosData(_polos)
            const anosSemestres = await getDataSource('/comercial/comercial_relatorio_comissao_rematricula/list-ano-semestre', 'GET')
            setSemestreData(anosSemestres.data)
            setSemestreValue(anosSemestres.data[0])
        }
        load()

    }, []);


    const upDateDataGridRows = async (polo, semestre) => setGridDataRows(dxCustomStore(URL, {
        polo: polo,
        semestre_atual: semestre
    }))






    return (<Main title={'/  Comercial / Relatório de comissões: Rematrícula'} privileges={props.privileges}>
        <div className="row pb-2">
            <div className="col-sm-4"><InputSelect caption={'Polo'} data={polosData} value={polosValue} setValue={setPolosValue} onChange={polosOnChange} isMulti={true} /></div>
            <div className="col-sm-2"><InputSelect caption={'Semestre'} data={semestreData} value={semestreValue} setValue={setSemestreValue} onChange={semestreOnChange} /></div>
        </div>

        <DataGrid
            id="grid"
            showBorders={true}
            dataSource={gridDataRows}
            repaintChangesOnly={false}
            columnAutoWidth={true}
            allowColumnResizing={true}
            allowColumnReordering={true}
            height="70vh"
            width="100%"
            hoverStateEnabled={true}
        >
            <ColumnChooser enabled={true} height={"90vh"} width={300} allowSearch={true} />
            <Grouping autoExpandAll={false} />
            <GroupPanel visible={true} />
            <Paging defaultPageSize={1000} />
            <Pager showPageSizeSelector={true} allowedPageSizes={[50, 100, 250, 500]} showInfo={true} />
            <HeaderFilter visible={true} />
            <SearchPanel visible={true} width={240} placeholder="Pesquisar..." />
            <Scrolling mode="standard" useNativ={true} showScrollbar="always" />
            <Selection mode="single" />

            <Column dataField="id" caption="id" visible={false} />
            <Column dataField="rgm" caption="RGM" visible={true} alignment="center" width="120" />
            <Column dataField="aluno_nome" caption="Aluno" visible={true} width="250" />

            <Column dataField="data_matricula" width="120" caption="Matrícula" visible={true} dataType={"date"} alignment="center" />
            <Column dataField="matricula_situacao_nome" caption="Situação" visible={true} />
            <Column dataField="curso_grau_nome" caption="Grau" visible={true} />
            <Column dataField="curso_nome" caption="Curso" visible={true} />
            <Column dataField="serie" caption="Série" visible={true} alignment="center" width="120" />
            <Column dataField="consultor_nome" caption="Consultor" visible={true} />
            <Column dataField="polo_nome" caption="Polo" visible={true} width="150" />

            <Summary><GroupItem column="id" displayFormat="{0}" summaryType="count" /></Summary>
        </DataGrid>

    </Main >
    )
}