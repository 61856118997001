import React, { useState, useEffect, useCallback, Fragment } from 'react'
import Main from '../../components/main/Main'
import CryptoJS from 'crypto-js'
import TextBox from 'devextreme-react/text-box';

import { Pager, Paging, Editing, ColumnChooser, Summary, GroupItem, Grouping, GroupPanel, DataGrid, Column, Selection, Scrolling, Popup, Lookup, HeaderFilter, SearchPanel } from 'devextreme-react/data-grid';
import { postData, customStoreWithToken, getDataSourceByPost, getDataSource } from '../../api/dataSource'
export default function (props) {


    const [poloId, setPoloId] = useState(-1)
    const [usuarioId, setUsuarioId] = useState(-1)
    const [acessoId, setAcessoId] = useState(-1)
    const [privilegeId, setPrivilegeId] = useState(-1)


    const [poloData, setPoloData] = useState([])
    const [usuariosData, setUsuariosData] = useState([])
    const [acessoData, setAcessoData] = useState([])
    const [privilegeData, setPrivilegeData] = useState([])




    useEffect(() => {

        const load = async () => {
            // Usuários.
            const usuariosResponse = await getDataSource('/sys/user',)
            const usuarios = usuariosResponse.data
            for (let index = 0; index < usuarios.length; index++) {
                const element = usuarios[index]
                let reqPasswordBytes = CryptoJS.AES.decrypt(element.password, process.env.REACT_APP_AUTH_SECRECT)
                let reqPasswordDecrypt = reqPasswordBytes.toString(CryptoJS.enc.Utf8)
                element.password_decrypt = reqPasswordDecrypt
            }
            setUsuariosData(usuarios)

            // Polos.
            const polosResponse = await getDataSource('/sys/polo')
            const polos = polosResponse.data
            setPoloData(polos)

            // Acessos.
            const acessosResponse = await getDataSource('/user_manage/access')
            const acessos = acessosResponse.data
            setAcessoData(acessos)

            //setUsuariosData(setFuncaoData.data)
        }
        load()

    }, []);



    const setPrivilegies = async (accessId) => {
        const check = []
        if (poloId == -1) check.push({ id: -1, nome: 'Selecione um polo' })
        if (usuarioId == -1) check.push({ id: -1, nome: 'Selecione um usuário' })
        if (check.length > 0) {
            setPrivilegeData(check)
            return
        }

        const listAllPrivilegesResponse = await getDataSource('/user_manage/list_all_privilege/' + accessId)
        const listAllPrivileges = listAllPrivilegesResponse.data

        const body = {
            polo_id: poloId,
            user_id: usuarioId,
            access_id: accessId,
        }

        const listUserPrivilegesResponse = await postData('/user_manage/list_user_privilege', body)
        const listUserPrivileges = listUserPrivilegesResponse.data

        for (let index = 0; index < listAllPrivileges.length; index++) {
            const privilege = listAllPrivileges[index]
            privilege.has_access = 0
            for (let index = 0; index < listUserPrivileges.length; index++) {
                const userPrivilege = listUserPrivileges[index]
                if (userPrivilege.privilege_id == privilege.id) privilege.has_access = 1
            }
        }
        setPrivilegeData(listAllPrivileges)
    }

    const getUser = () => {
        
    }

    const getAccess = () => {
        
    }    

    return (
        <Main title={'/  Admin  /  Usuários'} privileges={props.privileges}>


            <div className="row">
                <div className="col-sm-2">
                    <p className="fw-bold m-0 p-0">Polo</p>
                    <DataGrid className="mb-4"
                        id="grid_Polo"
                        keyExpr={'id'}
                        showBorders={true}
                        focusedRowEnabled={true}
                        dataSource={poloData}
                        repaintChangesOnly={false}
                        columnAutoWidth={true}
                        allowColumnResizing={true}
                        height="200px"
                        width="100%"

                        onCellClick={(e) => { setPoloId(e.data.id) }}
                    >
                        <Selection mode="single" />
                        <Column dataField="id" caption="ID" visible={false} />
                        <Column dataField="nome" caption="Nome" visible={true} />
                    </DataGrid>
                </div>
                <div className="col-sm-10">
                    <p className="fw-bold m-0 p-0">Usuário</p>
                    <DataGrid className="mb-4"
                        id="grid_usuarios"
                        keyExpr={'id'}
                        showBorders={true}
                        focusedRowEnabled={true}
                        dataSource={usuariosData}
                        repaintChangesOnly={false}
                        columnAutoWidth={true}
                        allowColumnResizing={true}
                        height="200px"
                        width="100%"
                        onCellClick={(e) => { setUsuarioId(e.data.id) }}
                    >
                        <Selection mode="single" />
                        <Column dataField="id" caption="ID" visible={false} />
                        <Column dataField="full_name" caption="Nome" visible={true} />
                        <Column dataField="email" caption="E-mail" visible={true} />
                        <Column dataField="password_decrypt" caption="Senha Decrypt" visible={true} />
                        <Column dataField="password" caption="Senha" visible={true} />
                        <Column dataField="cpf" caption="CPF" visible={true} />
                        <Column dataField="active" caption="Ativo" visible={true} />
                    </DataGrid>
                </div>
            </div>


            <div className="row">
                <div className="col-sm-4">
                    <p className="fw-bold m-0 p-0">Acessos</p>
                    <DataGrid className="mb-4"
                        id="grid_Polo"
                        keyExpr={'id'}
                        showBorders={true}
                        focusedRowEnabled={true}
                        dataSource={acessoData}
                        repaintChangesOnly={false}
                        columnAutoWidth={true}
                        allowColumnResizing={true}
                        height="400px"
                        width="100%"

                        onCellClick={(e) => {
                            setPrivilegies(e.data.id)
                            setAcessoId(e.data.id)
                        }}
                    >
                        <Selection mode="single" />
                        <Column dataField="id" caption="ID" visible={false} />
                        <Column dataField="nome" caption="Nome" visible={true} />
                    </DataGrid>
                </div>
                <div className="col-sm-8">
                    <p className="fw-bold m-0 p-0">Privilégios</p>
                    <DataGrid className="mb-4"
                        id="grid_usuarios"
                        keyExpr={'id'}
                        showBorders={true}
                        focusedRowEnabled={true}
                        dataSource={privilegeData}
                        repaintChangesOnly={false}
                        columnAutoWidth={true}
                        allowColumnResizing={true}
                        height="400px"
                        width="100%"
                        onCellClick={(e) => {
                            //setUsuario(e.data.id)
                        }}


                        onOptionChanged={async (e) => {
                            console.log(e)
                        }}


                        onCellDblClick={async (e) => {
                            console.log('Polo: ' + poloId)
                            console.log('Usuário: ' + usuarioId)
                            console.log('Acesso: ' + acessoId)
                            // console.log('Privilegio: ' + poloId)

                            const body = {
                                polo_id: poloId,
                                user_id: usuarioId,
                                access_id: acessoId,
                                privilege_id: e.data.id,
                            }

                            if (e.data.has_access == 0) {
                                console.log('INSERE')
                                await postData('/user_manage/set_privilege', body)
                            }
                            if (e.data.has_access == 1) {
                                console.log('REMOVE')
                                await postData('/user_manage/remove_privilege', body)
                            }

                            setPrivilegies(acessoId)
                        }}



                    >
                        <Selection mode="single" />
                        <Column dataField="id" caption="ID" visible={false} />
                        <Column dataField="key_value" caption="Nome" visible={false} />
                        <Column dataField="nome" caption="Nome" visible={true} />
                        <Column dataField="has_access" caption="Acesso" visible={true} />
                    </DataGrid>
                </div>
            </div>

        </Main >
    );
}