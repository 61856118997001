import React, { useState, useEffect } from 'react';
import { Pager, Paging, ColumnChooser, Summary, GroupItem, Grouping, GroupPanel, TotalItem, DataGrid, Column, Selection, Scrolling, Popup, Lookup, Form, HeaderFilter, SearchPanel } from 'devextreme-react/data-grid';
import 'devextreme-react/text-area';

import SibeComponents from '../../components/drop-down-box/SibeComponents'
import { customStoreWithToken, getDataSource, getDataSourceByPost, postData } from '../../api/dataSource'
import { useAuth } from '../../contexts/auth'
// import { GetPolosAccess } from '../../../config/user_profile'



import Main from '../../components/main/Main'
let sibeComponents = new SibeComponents();


const URL = '/robo_siaa/relacao_candidatos_inscritos/list-inscritos'

export default function () {

    // console.log(props)

    const { user } = useAuth()
    const [dataRows, setDataRows] = useState([])

    const [polosTodosValue, setPolosTodosValue] = useState([])
    const [polosTodosData, setPolosTodosData] = useState([])

    const [polosGrupoValue, setPolosGrupoValue] = useState([])
    const [polosGrupoData, setPolosGrupoData] = useState([])

    const [polosFavoritoValue, setPolosFavoritoValue] = useState([])
    const [polosFavoritoData, setPolosFavoritoData] = useState([])

    const [anoSemestreValue, setAnoSemestreValue] = useState([])
    const [anoSemestreData, setAnoSemestreData] = useState([])

    const [cidadesValue, setCidadesValue] = useState([])
    const [cidadesData, setCidadesData] = useState([])

    const [estadosValue, setEstadosValue] = useState([])
    const [estadosData, setEstadosData] = useState([])


    const [loadComplete, setLoadComplete] = useState(false)

    useEffect(() => {
        const load = async () => {
            const resPolosTodos = await postData('/robo_siaa/relacao_candidatos_inscritos/list-polos-todos')
            setPolosTodosData(resPolosTodos.data)

            const resPolosGrupo = await postData('/robo_siaa/relacao_candidatos_inscritos/list-polos-grupo')
            setPolosGrupoData(resPolosGrupo.data)

            const resPolosFavorito = await postData('/robo_siaa/relacao_candidatos_inscritos/list-polos-favorito')
            setPolosFavoritoData(resPolosFavorito.data)

            const resAnoSemestre = await postData('/robo_siaa/relacao_candidatos_inscritos/list-semestres')
            setAnoSemestreData(resAnoSemestre.data)

            const resCidades = await postData('/robo_siaa/relacao_candidatos_inscritos/list-cidades')
            setCidadesData(resCidades.data)

            const resEstados = await postData('/robo_siaa/relacao_candidatos_inscritos/list-estados')
            setEstadosData(resEstados.data)
        }
        load()
        setLoadComplete(true)
    }, []);

    

    const onPolosTodosValueChange = (value) => {
        setPolosTodosValue(value)
        if (loadComplete) upDateDataGridRows(value, polosGrupoValue, polosFavoritoValue, anoSemestreValue, cidadesValue, estadosValue)
    }

    const onPolosGrupoValueChange = (value) => {
        setPolosGrupoValue(value)
        if (loadComplete) upDateDataGridRows(polosTodosValue, value, polosFavoritoValue, anoSemestreValue, cidadesValue, estadosValue)
    }

    const onPolosFavoritoValueChange = (value) => {
        setPolosFavoritoValue(value)
        if (loadComplete) upDateDataGridRows(polosTodosValue, polosGrupoValue, value, anoSemestreValue, cidadesValue, estadosValue)
    }

    const onAnoSemestreValueChange = (value) => {
        setAnoSemestreValue(value)
        if (loadComplete) upDateDataGridRows(polosTodosValue, polosGrupoValue, polosFavoritoValue, value, cidadesValue, estadosValue)
    }

    const onCidadesValueChange = (value) => {
        setCidadesValue(value)
        if (loadComplete) upDateDataGridRows(polosTodosValue, polosGrupoValue, polosFavoritoValue, anoSemestreValue, value, estadosValue)
    }

    const onEstadosValueChange = (value) => {
        setEstadosValue(value)
        if (loadComplete) upDateDataGridRows(polosTodosValue, polosGrupoValue, polosFavoritoValue, anoSemestreValue, cidadesValue, value)
    }    

    const upDateDataGridRows = async (polosTodos, polosGrupo, polosFavorito, ano_semestre = anoSemestreValue, cidades, estados) => {
        const body = {
            ano_semestre: ano_semestre,
            polos_todos: polosTodos,
            polos_grupo: polosGrupo,
            polos_favorito: polosFavorito,
            cidades: cidades,
            estados: estados
        }
        const data = await getDataSourceByPost(URL, body)
        setDataRows(data.data)
    }

    return (<Main title={'/  Robô SIAA  /  Relação De Candidados Inscritos'} >

        <div className="row">
            <div className="col-2 p-0 m-0">
                <div className="d-flex flex-column ps-3">
                    <div className="p-0 m-0 fw-bold">Ano/Semestre:</div>
                    <div className="p-0 m-0">{sibeComponents.SelectMultiple(anoSemestreData, anoSemestreValue, onAnoSemestreValueChange, false)}</div>
                </div>
            </div>

            <div className="col-2 p-0 m-0">
                <div className="d-flex flex-column ps-3">
                    {/* <div className="p-0 m-0 fw-bold">Ano/Semestre:</div>
                    <div className="p-0 m-0">{sibeComponents.SelectMultiple(anoSemestreData, anoSemestreValue, onAnoSemestreValueChange, false)}</div> */}
                </div>
            </div>


            <div className="col-6 p-0 m-0">
                <div className="d-flex flex-column ps-3">
                    <div className="p-0 m-0 fw-bold">Cidades:</div>
                    <div className="p-0 m-0">{sibeComponents.SelectMultiple(cidadesData, cidadesValue, onCidadesValueChange, true)}</div>
                </div>
            </div>
            <div className="col-2 p-0 m-0">
                <div className="d-flex flex-column ps-3">
                    <div className="p-0 m-0 fw-bold">Estados:</div>
                    <div className="p-0 m-0">{sibeComponents.SelectMultiple(estadosData, estadosValue, onEstadosValueChange, true)}</div>
                </div>
            </div>



        </div>

        <div className="row mt-2">
            <div className="col-4 p-0 m-0">
                <div className="d-flex flex-column ps-3">
                    <div className="p-0 m-0 fw-bold">Polos (grupo):</div>
                    <div className="p-0 m-0">{sibeComponents.SelectMultiple(polosGrupoData, polosGrupoValue, onPolosGrupoValueChange, true)}</div>
                </div>
            </div>


            <div className="col-4 p-0 m-0">
                <div className="d-flex flex-column ps-3">
                    <div className="p-0 m-0 fw-bold">Polos (favorito):</div>
                    <div className="p-0 m-0">{sibeComponents.SelectMultiple(polosFavoritoData, polosFavoritoValue, onPolosFavoritoValueChange, true)}</div>
                </div>
            </div>

            <div className="col-4 p-0 m-0">
                <div className="d-flex flex-column ps-3">
                    <div className="p-0 m-0 fw-bold">Polos (todos):</div>
                    <div className="p-0 m-0">{sibeComponents.SelectMultiple(polosTodosData, polosTodosValue, onPolosTodosValueChange, true)}</div>
                </div>
            </div>


        </div>

        <div className="row mt-2">

        </div>




        <DataGrid className="mt-3 border-dark border-top"
            id="grid"
            showBorders={true}
            dataSource={dataRows}
            repaintChangesOnly={true}
            columnAutoWidth={true}
            allowColumnResizing={true}
            allowColumnReordering={true}
            height="70vh"
            width="100%"
            hoverStateEnabled={true}
        >
            <ColumnChooser enabled={true} height={"90vh"} width={300} allowSearch={true} />
            <Grouping autoExpandAll={false} />
            <GroupPanel visible={true} />
            <Paging defaultPageSize={500} />
            <Pager showPageSizeSelector={true} allowedPageSizes={[50, 100, 250, 500]} showInfo={true} />
            <HeaderFilter visible={true} />
            <SearchPanel visible={true} width={240} placeholder="Pesquisar..." />
            <Scrolling mode="standard" useNativ={true} showScrollbar="always" />
            <Selection mode="single" />

            <Column dataField="id" caption="" visible={false} alignment="center" allowSorting={false} allowFiltering={false} allowReordering={false} allowGrouping={false} />

            <Column dataField="data_inscricao" width="120" caption="Data" visible={true} dataType={"date"} defaultSortOrder="desc" alignment="center" />
            <Column dataField="nome" caption="Nome" visible={true} width="250" />
            <Column dataField="cpf" caption="CPF" visible={true} width="100" />
            <Column dataField="polo" caption="Polo" visible={true} />
            <Column dataField="cidade" caption="Cidade" visible={true} width="150" />
            <Column dataField="estado" caption="UF" visible={true} width="150" />
            <Column dataField="situacao" caption="Status (SIAA)" visible={true} width="120" groupIndex={-1} autoExpandGroup={false} />
            <Column dataField="data_matricula" width="120" caption="Matrícula" visible={true} dataType={"date"} alignment="center" />
            <Column dataField="curso" caption="Curso" visible={true} width="250" />



            {/* <Column dataField="fone_cel" caption="Fone Cel." visible={true} width="110" />
            <Column dataField="fone_res" caption="Fone Res." visible={true} width="80" />
            <Column dataField="fone_com" caption="Fone Com." visible={true} width="80" />
            <Column dataField="email" caption="E-mail" visible={true} width="80" />
            <Column dataField="endereco_municipio_uf" caption="Município/UF" visible={true} width="150" /> */}


            <Summary>
                <TotalItem column="nome" summaryType="count" />
                <GroupItem column="nome" displayFormat="{0}" summaryType="count" />
            </Summary>
        </DataGrid>





    </Main>
    )
}