import React from 'react'
import DataGrid, { LoadPanel, Toolbar, Pager, Paging, ColumnChooser, Summary, GroupItem, Grouping, GroupPanel, Column, Selection, Scrolling, Popup, Lookup, HeaderFilter, SearchPanel } from 'devextreme-react/data-grid'
export default function (props) {
  const renderPanelLeadQtd = (title, content, backgroundColor, fontColor) => {

    let bgColorContent = ''
    let textColorContent = fontColor
    if (content == '0') {
      bgColorContent = 'bg-danger'
      textColorContent = 'text-white'
    }

    const cssTitle = `fw-bold py-2 px-1  ${backgroundColor} ${fontColor} text-center`
    const cssContent = `fw-bold py-2 px-1 ${bgColorContent} text-center ${textColorContent} border-top border-dark`
    return <div className="d-flex flex-column mx-1 flex-fill border border-dark">
      <div className={cssTitle}>{title}</div>
      <div className={cssContent}>{content}</div>
    </div>
  }

  return <React.Fragment>
    <div className='h6 mt-4 mb-0'>Quantidades por Período</div>
    <DataGrid
      className={''}
      showBorders={true}
      showColumnLines={false}
      showColumnHeaders={true}
      showRowLines={false}
      dataSource={props.dashBoardValues.NewByInterval}
      repaintChangesOnly={true}
      columnAutoWidth={false}
      allowColumnResizing={false}
      allowColumnReordering={false}
      allowSorting={false}
      onRowPrepared={(e) => {
        e.cells.forEach(element => element.cellElement.style.textAlign = 'center')

        if (e.rowType == 'header') {
          // aaaaaa
          e.cells['0'].cellElement.style.backgroundColor = '#aaaaaa'
          e.cells['1'].cellElement.style.backgroundColor = '#aaaaaa'
          e.cells['2'].cellElement.style.backgroundColor = '#aaaaaa'
          e.cells['3'].cellElement.style.backgroundColor = '#aaaaaa'
          e.cells['4'].cellElement.style.backgroundColor = '#aaaaaa'
          e.cells['5'].cellElement.style.backgroundColor = '#aaaaaa'
          e.cells['6'].cellElement.style.backgroundColor = '#aaaaaa'
          e.cells['7'].cellElement.style.backgroundColor = '#aaaaaa'

          // e.cells['0'].cellElement.style.backgroundColor = '#f6cd61'
          e.cells['0'].cellElement.style.color = '#000'

          // e.cells['1'].cellElement.style.backgroundColor = '#3385c6'
          e.cells['1'].cellElement.style.color = '#000'

          // e.cells['2'].cellElement.style.backgroundColor = '#4279a3'
          e.cells['2'].cellElement.style.color = '#000'

          // e.cells['3'].cellElement.style.backgroundColor = '#476c8a'
          e.cells['3'].cellElement.style.color = '#000'

          // e.cells['4'].cellElement.style.backgroundColor = '#49657b'
          e.cells['4'].cellElement.style.color = '#000'

          // e.cells['5'].cellElement.style.backgroundColor = '#ffc425'
          e.cells['5'].cellElement.style.color = '#000'

          // e.cells['6'].cellElement.style.backgroundColor = '#f37735'
          e.cells['6'].cellElement.style.color = '#000'

          e.cells['7'].cellElement.style.color = '#000'
        }

      }}
    >
      <Scrolling mode="standard" useNativ={true} showScrollbar="always" />
      <Selection mode="single" />
      <Column dataField="id" caption="ID" visible={false} />
      <Column dataField="Today" caption="Hoje" />
      <Column dataField="CurrentWeek" caption="Semana atual" />
      <Column dataField="Day10" caption="Ult. 10 dias" />
      <Column dataField="Day15" caption="Ult. 15 dias" />
      <Column dataField="Day20" caption="Ult. 20 dias" />
      <Column dataField="CurrentMonth" caption="Mês atual" />
      <Column dataField="LastMonth" caption="Mês Ant." />
      <Column dataField="Total" caption="Total" />
    </DataGrid>


    <div className='h6 mt-4 mb-0'>Quantidades por Situação</div>
    <DataGrid
      className={'mt-2'}
      showBorders={true}
      showColumnLines={false}
      showColumnHeaders={true}
      showRowLines={false}
      dataSource={props.dashBoardValues.TotalBySituacao}
      repaintChangesOnly={true}
      columnAutoWidth={false}
      allowColumnResizing={false}
      allowColumnReordering={false}
      allowSorting={false}
      onRowPrepared={(e) => {
        e.cells.forEach(element => element.cellElement.style.textAlign = 'center')

        if (e.rowType == 'header') {
          e.cells['0'].cellElement.style.backgroundColor = '#aaaaaa'
          e.cells['1'].cellElement.style.backgroundColor = '#aaaaaa'
          e.cells['2'].cellElement.style.backgroundColor = '#aaaaaa'
          e.cells['3'].cellElement.style.backgroundColor = '#aaaaaa'
          e.cells['4'].cellElement.style.backgroundColor = '#aaaaaa'
          e.cells['5'].cellElement.style.backgroundColor = '#aaaaaa'
          e.cells['6'].cellElement.style.backgroundColor = '#aaaaaa'
          e.cells['7'].cellElement.style.backgroundColor = '#aaaaaa'
          
          // e.cells['0'].cellElement.style.backgroundColor = '#ffd3b6'
          e.cells['0'].cellElement.style.color = '#000'

          // e.cells['1'].cellElement.style.backgroundColor = '#dcedc1'
          e.cells['1'].cellElement.style.color = '#000'

          // e.cells['2'].cellElement.style.backgroundColor = '#a8e6cf'
          e.cells['2'].cellElement.style.color = '#000'

          // e.cells['3'].cellElement.style.backgroundColor = '#ffcc5c'
          e.cells['3'].cellElement.style.color = '#000'

          // e.cells['4'].cellElement.style.backgroundColor = '#adcbe3'
          e.cells['4'].cellElement.style.color = '#000'

          // e.cells['5'].cellElement.style.backgroundColor = '#4b86b4'
          e.cells['5'].cellElement.style.color = '#000'

          // e.cells['6'].cellElement.style.backgroundColor = '#aaaaaa'
          e.cells['6'].cellElement.style.color = '#000'

          // e.cells['7'].cellElement.style.backgroundColor = '#ff6f69'
          e.cells['7'].cellElement.style.color = '#000'

        }

      }}
    >
      <Scrolling mode="standard" useNativ={true} showScrollbar="always" />
      <Selection mode="single" />
      <Column dataField="id" caption="ID" visible={false} />
      <Column dataField="qtdProspect" caption="Prospect" />
      <Column dataField="qtdInteressado" caption="Interessado" />
      <Column dataField="qtdNegociando" caption="Negociando" />
      <Column dataField="qtdInteresseFuturo" caption="Interesse futuro" />
      <Column dataField="qtdInscrito" caption="Inscrito" />
      <Column dataField="qtdMatriculado" caption="Matriculado" />
      <Column dataField="qtdDesistente" caption="Desistente" />
      <Column dataField="NaoDeuRetornoNaoResponde" caption="Ñ deu retorno/Ñ responde" />
    </DataGrid>

  </React.Fragment >
}