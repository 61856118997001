import React, { useState, useEffect } from 'react';
import { dxCustomStore } from '../../api/dataSource'

import Form, {
    SimpleItem, GroupItem, TabbedItem, TabPanelOptions, Tab,
  } from 'devextreme-react/form';

export default (props) => {
    // const [formData, setFormData] = useState()
    useEffect(() => {
        dxCustomStore('/academico/matricula/view', { matriculaId: props.matriculaId }).load().done(result => {
            // setFormData(result[0])

            if (result[0]) {
                const res = result[0]

                console.log(res)

                document.getElementById('aluno_nome').innerText = res.aluno_nome
                document.getElementById('curso_nome').innerText = res.curso_nome
                document.getElementById('curso_grau_nome').innerText = res.curso_grau_nome
                document.getElementById('matricula_rgm').innerText = res.matricula_rgm
                document.getElementById('polo_nome').innerText = res.polo_nome
                document.getElementById('matricula_data').innerText = FormatDate(res.matricula_data)
                document.getElementById('matricula_serie').innerText = res.matricula_serie
                document.getElementById('matricula_ingresso_ano_trimestre').innerText = res.matricula_ingresso_ano_trimestre
                document.getElementById('matricula_semestre').innerText = res.matricula_semestre
                document.getElementById('aluno_endereco_logradouro').innerText = res.aluno_endereco_logradouro
                document.getElementById('aluno_endereco_bairro').innerText = res.aluno_endereco_bairro
                document.getElementById('aluno_endereco_numero').innerText = res.aluno_endereco_numero
                document.getElementById('aluno_endereco_complemento').innerText = res.aluno_endereco_complemento
                document.getElementById('aluno_endereco_municipio').innerText = res.aluno_endereco_municipio
                document.getElementById('aluno_endereco_uf').innerText = res.aluno_endereco_uf
                document.getElementById('aluno_endereco_cep').innerText = res.aluno_endereco_cep
                document.getElementById('aluno_contato_telefone_celular').innerText = res.aluno_contato_telefone_celular
                document.getElementById('aluno_contato_telefone_comercial').innerText = res.aluno_contato_telefone_comercial
                document.getElementById('aluno_contato_telefone_residencial').innerText = res.aluno_contato_telefone_residencial
                document.getElementById('aluno_contato_email').innerText = res.aluno_contato_email
            }
        })





        console.log('useEffect: ' + props.matriculaId)
    }, [props.matriculaId]);

    const BuildField = (caption, id) => {
        return (
            <div>
                <span className="p-0 pe-2 fw-bold">{caption}:</span>
                <span id={id} className="p-0"></span>
            </div>
        )
    }

    const FormatDate = (date) => {
        try {
            return `${date.substring(8, 11)}/${date.substring(5, 7)}/${date.substring(0, 4)}`
        } catch (error) {
            return ''
        }
    }
 
    return <div className="container-fluid alert alert-secondary p-1">
        <div className="row">
            <div className="col-sm-4">{BuildField('Aluno', 'aluno_nome')}</div>
            <div className="col-sm-4">{BuildField('Curso', 'curso_nome')}</div>
            <div className="col-sm-2">{BuildField('Grau', 'curso_grau_nome')}</div>
            <div className="col-sm-2">{BuildField('RGM', 'matricula_rgm')}</div>
        </div>

        <div className="row">
            <div className="col-sm-4">{BuildField('Polo', 'polo_nome')}</div>
            <div className="col-sm-2">{BuildField('Matrícula', 'matricula_data')}</div>
            <div className="col-sm-2">{BuildField('Série', 'matricula_serie')}</div>
            <div className="col-sm-2">{BuildField('Ingresso', 'matricula_ingresso_ano_trimestre')}</div>
            <div className="col-sm-2">{BuildField('Semestre', 'matricula_semestre')}</div>

        </div>
        {/* <hr /> */}

        <div className="row">
            <div className="col-sm-6">{BuildField('Endereço', 'aluno_endereco_logradouro')}</div>
            <div className="col-sm-2">{BuildField('Nº', 'aluno_endereco_numero')}</div>
            <div className="col-sm-2">{BuildField('CEP', 'aluno_endereco_cep')}</div>
            <div className="col-sm-2">{BuildField('Comp.', 'aluno_endereco_complemento')}</div>

            <div className="col-sm-4">{BuildField('Bairro', 'aluno_endereco_bairro')}</div>
            <div className="col-sm-6">{BuildField('Município', 'aluno_endereco_municipio')}</div>
            <div className="col-sm-2">{BuildField('UF', 'aluno_endereco_uf')}</div>
        </div>
        {/* <hr /> */}

        <div className="row">
            <div className="col-sm-4">{BuildField('E-mail', 'aluno_contato_email')}</div>
            <div className="col-sm-2">{BuildField('Tel. Cel.', 'aluno_contato_telefone_celular')}</div>
            <div className="col-sm-2">{BuildField('Tel. Res.', 'aluno_contato_telefone_residencial')}</div>
            <div className="col-sm-2">{BuildField('Tel. Com.', 'aluno_contato_telefone_comercial')}</div>
        </div>
    </div>

}