import React, { useState, useRef, useCallback } from 'react';
import { Link } from 'react-router-dom';
import CryptoJS from 'crypto-js'

import Form, {
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  EmailRule
} from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import notify from 'devextreme/ui/notify';
import { useAuth } from '../../contexts/auth';

import './login-form.css';

export default function () {
  // const history = useHistory();
  const { signIn } = useAuth();
  const [loading, setLoading] = useState(false);
  const formData = useRef({});

  const onSubmit = useCallback(async (e) => {
    e.preventDefault();
    const email = formData.current.email
    const senha = CryptoJS.AES.encrypt(formData.current.password, process.env.REACT_APP_AUTH_SECRECT).toString();
    setLoading(true)

    const login = await signIn(email, senha)


    if (!login.auth) {
      setLoading(false)
      notify(login.message, 'error', 5000)
    }
  }, [signIn])

  return (


    <div className="container pt-5">
      <div className="d-flex justify-content-center pt-5">


        <div id="login_content" className="p-4 ">
          <span className="h4">Área Restrita</span>
          <form className={'login-form'} onSubmit={onSubmit}>
            <Form formData={formData.current} disabled={loading}>
              <Item
                dataField={'email'}
                editorType={'dxTextBox'}
                editorOptions={emailEditorOptions}
              >
                <RequiredRule message="E-mail é obrigatório" />
                <EmailRule message="E-mail é inválido" />
                <Label visible={false} />
              </Item>
              <Item
                dataField={'password'}
                editorType={'dxTextBox'}
                editorOptions={passwordEditorOptions}
              >
                <RequiredRule message="Senha é obrigatória" />
                <Label visible={false} />
              </Item>

              <ButtonItem>
                <ButtonOptions
                  width={'250'}
                  type={'default'}
                  useSubmitBehavior={true}
                >
                  <span className="dx-button-text">
                    {
                      loading
                        ? <LoadIndicator width={'24px'} height={'24px'} visible={true} />
                        : 'Entrar'
                    }
                  </span>
                </ButtonOptions>
              </ButtonItem>

              <Item>
                <div className={'link'}>
                  <Link to={'/reset-password'}>Esqueceu a senha?</Link>
                </div>
              </Item>

            </Form>
          </form>

        </div>
      </div>
      {/* <SingleCard className="" title="Área Restrita">

      </SingleCard> */}
    </div>
  );
}

const emailEditorOptions = { stylingMode: 'filled', placeholder: 'E-mail', mode: 'email' };
const passwordEditorOptions = { stylingMode: 'filled', placeholder: 'Senha', mode: 'password' };
