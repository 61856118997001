import React, { useState, useEffect } from 'react';


import DataGrid, { LoadPanel, Toolbar, Pager, Paging, ColumnChooser, Summary, GroupItem, Grouping, GroupPanel, Column, Selection, Scrolling, Popup, Lookup, HeaderFilter, SearchPanel } from 'devextreme-react/data-grid';
import 'devextreme-react/text-area';

import { dxCustomStore, getDataSource } from '../../../api/dataSource'
import Main from '../../../components/main/Main'
import { InputSelect } from '../../../components/Layout/Inputs/Select'

import { useAuth } from '../../../contexts/auth'
import { GetPolosAccess } from '../../../config/user_profile'

const URL = '/educamais/financeiro/list'

export default (props) => {
    const { user } = useAuth()
    const [gridDataRows, setGridDataRows] = useState(null)
    const [polosValue, setPolosValue] = useState([])
    const [polosData, setPolosData] = useState([])
    const polosOnChange = (value) => upDateDataGridRows(value)


    useEffect(() => {
        const load = async () => {
            const polosDataResponse = await GetPolosAccess('educamais_financeiro', user.id)
            setPolosData(polosDataResponse)
        }
        load()
    }, []);


    const upDateDataGridRows = async (polo) => setGridDataRows(dxCustomStore(URL, { polo: polo }))

    // const renderBtnEdit = (cellData) => {
    //     return (
    //         <button className="m-0 p-0 btn btn-sm" type="button" onClick={async () => {
    //             console.log(cellData)
    //             // setFormLeadId(cellData.value)
    //             setMatriculaId(cellData.data.matricula_id)
    //             setFormOpen(true)
    //         }}>
    //             <span><i className="m-0 p-0 me-1 fas fa-pen"></i>{cellData.value}</span>
    //         </button>
    //     )
    // }



    // const renderCsAtividadesEmDiaNome = (cellData) => {

    //     cellData.row.data.cs_status_id = "Em dia"

    //     const status = cellData.row.data.cs_status_id || 0
    //     if (status == 1) return <i className="text-success p-0 fas fa-check fw-bold"></i>
    //     return <i className="text-danger p-0 fas fa-times fw-bold"></i>
    // }

    // const renderAcompanhamento = (cellData) => {
    //     const quantidade = cellData.text
    //     if (quantidade == 0) return <span className="text-danger">{quantidade}</span>
    //     return <span>{quantidade}</span>
    // }

    // const renderCsStatusNome = (cellData) => {
    //     const status = cellData.row.data.cs_status_id || 0
    //     if (status == 1) return <i className="text-success p-0 fas fa-check fw-bold"></i>
    //     return <i className="text-danger p-0 fas fa-times fw-bold"></i>
    // }


    const headerCellRender = (cellData) => <span className="fw-bold">{cellData.column.caption}</span>

    const headerCellAtradadasRender = (cellData) => <span className="fw-bold text-danger">{cellData.column.caption}</span>
    const headerCellPagasRender = (cellData) => <span className="fw-bold text-success">{cellData.column.caption}</span>
    const headerCellTotalRender = (cellData) => <span className="fw-bold text-primary">{cellData.column.caption}</span>

    const cellAtrasadaRender = (cellData) => {
        if (cellData.value > 0) return <span className="fw-bold text-danger">
            <div>
            {cellData.value}
            </div>
        </span>
        return <span className="">{cellData.value}</span>
    }

    const cellSituacaoRender = (cellData) => {
        if (cellData.value == 'PAGA') return <span className="fw-bold text-success">{cellData.value}</span>
        if (cellData.value == 'EM DIA') return <span className="fw-bold text-primary">{cellData.value}</span>
        if (cellData.value == 'VENCIDA') return <span className="fw-bold text-danger">{cellData.value}</span>
        return <span className="">{cellData.value}</span>
    }

    


    return (<Main title={'/ EducaMais / Financeiro'} privileges={props.privileges}>
        <div className="row pb-2">
            <div className="col-sm-4"><InputSelect caption={'Polo'} data={polosData} value={polosValue} setValue={setPolosValue} onChange={polosOnChange} isMulti={true} /></div>
        </div>

        <DataGrid
            id="grid"
            showBorders={true}
            dataSource={gridDataRows}
            repaintChangesOnly={false}
            columnAutoWidth={true}
            allowColumnResizing={true}
            allowColumnReordering={true}
            height="70vh"
            width="100%"
            hoverStateEnabled={true}
        >
            <ColumnChooser enabled={true} height={"90vh"} width={300} allowSearch={true} />
            <Grouping autoExpandAll={false} />
            <GroupPanel visible={true} />
            <Paging defaultPageSize={1000} />
            <Pager showPageSizeSelector={true} allowedPageSizes={[50, 100, 250, 500]} showInfo={true} />
            <HeaderFilter visible={true} />
            <SearchPanel visible={true} width={240} placeholder="Pesquisar..." />
            <Scrolling mode="standard" useNativ={true} showScrollbar="always" />
            <Selection mode="single" />
            {/* <Column caption="Alunos Matriculados" alignment="center" headerCellRender={headerCellRender}>
                <Column
                    allowReordering={false} allowResizing={false} allowFiltering={false} allowSorting={false} allowGrouping={false}
                    dataField="id" width="50" caption="" visible={true} alignment="center" cellRender={renderBtnEdit} />
                <Column dataField="aluno_nome" caption="Nome" visible={true} />
                <Column dataField="matricula_rgm" caption="RGM" visible={true} />
                <Column dataField="polo_nome" caption="Polo" visible={true} />
            </Column> */}
            {/* <Column caption="Acompanhamento" alignment="center" headerCellRender={headerCellRender}>


                
                <Column dataField="cs_atividades_em_dia_value" cellRender={renderCsAtividadesEmDiaNome} caption="Atividades" alignment="center" visible={true} width="100" />



                <Column dataField="cs_quantidade_acompanhamento" cellRender={renderAcompanhamento} caption="Quantidade" alignment="center" visible={true} width="130" />
                <Column dataField="cs_ultimo_acompanhamento_data" caption="Último" visible={true} dataType={"date"} alignment="center" width="100" />
                <Column dataField="cs_proximo_acompanhamento_data" caption="Próximo" alignment="center" dataType={"date"} visible={true} width="100" />

                <Column dataField="cs_status_nome" cellRender={renderCsStatusNome} caption="Status" alignment="center" visible={true} width="100" />

                



            </Column> */}
            <Column caption="Aluno" alignment="center" headerCellRender={headerCellRender}>
                <Column dataField="aluno_nome" caption="Nome" visible={true} />
                <Column dataField="aluno_endereco_municipio" caption="Município" width="120" visible={true} />
                <Column dataField="possui_debitos" caption="Débitos" width="100" alignment="center" visible={true} />
            </Column>
            <Column caption="Mensalidade" alignment="center" headerCellRender={headerCellRender}>
                <Column dataField="competencia" caption="Competência" groupIndex={0} autoExpandGroup={true} visible={true} />
                <Column dataField="parcela" caption="Parcela" visible={true} width="120" alignment="center" />
                <Column dataField="valor" caption="Valor" visible={true} width="120" alignment="center" />
                <Column dataField="data_vencimento" caption="Vcto" visible={true} dataType={"date"} alignment="center" width="120" />
                <Column dataField="numero_boleto" caption="N° boleto" visible={true}  alignment="center" />
                <Column dataField="mensalidade_situacao" caption="Situação" visible={true} width="120" alignment="center" cellRender={cellSituacaoRender} />
            </Column>



            <Column caption="Atrasadas" alignment="center" headerCellRender={headerCellAtradadasRender}>
                <Column dataField="soma_mensalidades_atrasadas" caption="Qtd" visible={true} width="80" alignment="center" headerCellRender={headerCellAtradadasRender} cellRender={cellAtrasadaRender} />
                <Column dataField="soma_valores_atrasados" caption="Valor" visible={true} width="100" alignment="center" headerCellRender={headerCellAtradadasRender} />
            </Column>

            <Column caption="Pagas" alignment="center" headerCellRender={headerCellPagasRender}>
                <Column dataField="soma_mensalidades_pagas" caption="Qtd" visible={true} width="80" alignment="center" headerCellRender={headerCellPagasRender} />
                <Column dataField="soma_valores_pagos" caption="Valor" visible={true} width="100" alignment="center" headerCellRender={headerCellPagasRender} />
            </Column>

            <Column caption="Total" alignment="center" headerCellRender={headerCellTotalRender}>
                <Column dataField="soma_mensalidades" caption="Qtd" visible={true} width="80" alignment="center" headerCellRender={headerCellTotalRender} />
                <Column dataField="soma_valores" caption="Valor" visible={true} width="100" alignment="center" headerCellRender={headerCellTotalRender} />
            </Column>





            {/* <Column dataField="nome" caption="Nome" visible={true} width="200" /> */}

            {/* <Column dataField="data_matricula" caption="Data" visible={true} dataType={"date"} alignment="center" width="100" /> */}
            {/* <Column dataField="matricula_ingresso" caption="Ingresso" visible={true} alignment="center" width="100" /> */}
            {/* <Column dataField="matricula_serie" caption="Série" visible={true} alignment="center" width="100" /> */}

            <Summary><GroupItem column="id" displayFormat="{0}" summaryType="count" /></Summary>
        </DataGrid>


        {/* <Form
            formOpen={formOpen}
            setFormOpen={setFormOpen}
            matriculaId={matriculaId}
            upDateDataGridRows={upDateDataGridRows}
            polo={polosValue}
            semestre={semestreValue.nome}
        /> */}



    </Main >
    )
}