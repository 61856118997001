import React from 'react';
import Main from '../../components/main/Main'

export default () => (
  <Main title={'/  Home'} is_home={true}>

    <h2 className=''>Olá, seja bem-vindo!</h2>
    <div className=''>
      <div className=''>
        <p>Navegue utilizando o menu acima.</p>
      </div>
    </div>
  </Main>
);