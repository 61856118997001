import { postData, getDataSourceByPost, getDataSource } from '../api/dataSource'
import { useAuth } from '../contexts/auth'

const URL = '/user/profile'


export const GetPolosAccess = async (userAccess, userId) => {
    const result = await getDataSource(URL + `/polos_access/${userAccess}/${userId}`)
    // console.log(result.data)
    return result.data
}

// export const GetPolosAccess = async (userAccess, userId) => {
//     const result = await getDataSource(URL + `/polos_access/${userAccess}/${userId}`)
//     console.log(result.data)
//     return result.data
// }



export const getAccess = async (user_id, polo_id, sys_user_access_key, sys_user_access_privilege_key) => {
    try {
        console.log('getAccess')
        const body = {
            user_id: user_id,
            polo_id: polo_id,
            sys_user_access_key: sys_user_access_key,
            sys_user_access_privilege_key: sys_user_access_privilege_key
        }
        const accessResponse = await getDataSourceByPost('/sys/user/sys_user_profile/get', body)
        console.log(accessResponse.isOk)

        return accessResponse.isOk
    } catch (error) {
        return false
    }

}