import React, { useState, useEffect } from 'react'
import { Popup } from 'devextreme-react/popup';

import ScrollView from 'devextreme-react/scroll-view';
import Form, { Item, ButtonItem, ButtonOptions, EmptyItem, SimpleItem, Label } from 'devextreme-react/form';
import DataGrid, { LoadPanel, Pager, Paging, Summary, GroupItem, Column, Selection, Scrolling, Lookup, HeaderFilter, SearchPanel } from 'devextreme-react/data-grid'
import { SelectBox } from 'devextreme-react/select-box';
import './lead-form.css'
import { useAuth } from '../../../contexts/auth';
import { getAccess } from '../../../config/user_profile'
import { getDataSourceByPost, dxCustomStore } from '../../../api/dataSource'
import { Button } from 'devextreme-react/button';
import TextBox from 'devextreme-react/text-box';

import AlunoDetails from '../../../components/aluno/AlunoDetails'



export default (props) => {
  const { user } = useAuth()
  const [acompanhamentosData, setAcompanhamentosData] = useState([])
  const [acompanhamentoValue, setAcompanhamentoValue] = useState('')
  const [rematriculaSituacaoIdValue, setRematriculaSituacaoIdValue] = useState()

  const [consultorData, setConsultorData] = useState()


  const [consultorIdValue, setConsultorIdValue] = useState()


  const [rematriculaSituacaoData, setRematriculaSituacaoData] = useState([])

  useEffect(() => {
    const load = async () => {
      const data = await getDataSourceByPost('/gestao/relacao_alunos_rematricula/list-situacao', [])
      setRematriculaSituacaoData(data.data)
    }
    load()
  }, []);

  const OnClickCancelar = function () {
    resetForm()
    props.upDateDataGridRows(props.polos)
    props.setFormOpen(false)
  }

  const getSituacaoById = (id, data, fieldValue, fieldText) => {
    // const teste = []

    for (let index = 0; index < data.length; index++) {
      const element = data[index]      
      if (element[fieldValue] == id) return element[fieldText]
    }
  }

  const OnClickSalvar = async function () {
    let loadPromise

    const body = {
      instituicao_id: props.alunoData.instituicao_id,
      polo_id: props.alunoData.polo_id,
      curso_id: props.alunoData.curso_id,
      curso_grau_id: props.alunoData.curso_grau_id,
      rgm: props.alunoData.rgm,
      semestre: props.alunoData.semestre,

      rematricula_situacao_id: rematriculaSituacaoIdValue,
      rematricula_consultor_id: consultorIdValue,
      acompanhamentos: acompanhamentosData
    }

    const dxCustomStoreLead = dxCustomStore('/gestao/relacao_alunos_rematricula/save-acompanhamento')
    loadPromise = dxCustomStoreLead.insert(body)
    loadPromise.done(doneCallback => {
      props.upDateDataGridRows(props.polos)
      props.setFormOpen(false)
    })
  }

  const IncluirAcompanhamento = (registro) => {
    registro = registro.trim()
    if (registro) {
      let acompanhamentosLoad = []
      acompanhamentosLoad.push({
        id: 0,
        lead_id: 0,
        data_hora: new Date(),
        consultor_id: user.id,
        consultor_nome: user.full_name,
        descricao: registro
      })

      acompanhamentosLoad.concat(acompanhamentosData)
      setAcompanhamentosData(acompanhamentosLoad.concat(acompanhamentosData))
    }
    // setAcompanhamentoValue('')
  }

  const OnClickIncluirAcompanhamento = () => {
    const acompanhamento = acompanhamentoValue.toString().trim()
    if (acompanhamento) {
      let acompanhamentosLoad = []
      acompanhamentosLoad.push({
        id: 0,
        lead_id: 0,
        data_hora: new Date(),
        consultor_id: user.id,
        consultor_nome: user.full_name,
        descricao: acompanhamento
      })

      acompanhamentosLoad.concat(acompanhamentosData)
      setAcompanhamentosData(acompanhamentosLoad.concat(acompanhamentosData))
    }
    setAcompanhamentoValue('')
  }


  const resetForm = () => {
    setConsultorIdValue(null)
    setRematriculaSituacaoIdValue(null)
    setAcompanhamentoValue('')
    setAcompanhamentosData()
  }

  const BuildField = (caption, value) => {
    return (
      <div className="d-flex flex-column">
        <div className="p-0 fw-bold">{caption}</div>
        <div className="p-0">{value}</div>
      </div>
    )
  }


  return (
    <Popup
      visible={props.formOpen}
      onHiding={OnClickCancelar}
      dragEnabled={true}
      closeOnOutsideClick={false}
      showCloseButton={true}
      showTitle={true}
      title={'Registro de rematrícula'}
      container=".dx-viewport"
      width={'85vw'}
      onShowing={async () => {
        const poloId = props.alunoData.polo_id || -1

        const body = {
          instituicao_id: props.alunoData.instituicao_id,
          polo_id: props.alunoData.polo_id,
          curso_id: props.alunoData.curso_id,
          curso_grau_id: props.alunoData.curso_grau_id,
          rgm: props.alunoData.rgm,
        }

        
        setRematriculaSituacaoIdValue(props.alunoData.rematricula_situacao_id)
        
        
        // Consultor
        const resultConsultor = await getDataSourceByPost('/gestao/relacao_alunos_rematricula/list-consultor/' + poloId, [])        
        setConsultorData(resultConsultor.data)               
        setConsultorIdValue(props.alunoData.rematricula_consultor_id)

        const dxDataSourceLead = dxCustomStore('/gestao/relacao_alunos_rematricula/list-acompanhamento', body)
        var loadPromise = dxDataSourceLead.load()
        loadPromise.done((resultLead) => {
          const acompanhamentos = resultLead          
          setAcompanhamentosData(acompanhamentos)
        })
      }}
    >
      <ScrollView width='100%' height='100%'>
        <AlunoDetails
          alunoData={props.alunoData}
        />

        <div className="row">
          <div className="col-sm-4">{BuildField('Situação',
            <SelectBox
              dataSource={rematriculaSituacaoData}
              valueExpr="id"
              displayExpr="nome"
              onValueChanged={async (e) => {
                if (e.value != e.previousValue && e.previousValue > 0) {
                  const oldText = getSituacaoById(e.previousValue, rematriculaSituacaoData, 'id', 'nome')
                  const newText = getSituacaoById(e.value, rematriculaSituacaoData, 'id', 'nome')
                  IncluirAcompanhamento('Situação alterada: ' + oldText + ' para ' + newText)
                }
                setRematriculaSituacaoIdValue(e.value)
              }}
              value={rematriculaSituacaoIdValue}
            />
          )}</div>

          <div className="col-sm-4">{BuildField('Consultor',
            <SelectBox
              dataSource={consultorData}
              valueExpr="id"
              displayExpr="full_name"
              onValueChanged={(e) => {
                if (e.value != e.previousValue) {
                  const oldText = getSituacaoById(e.previousValue, consultorData, 'id', 'full_name')
                  const newText = getSituacaoById(e.value, consultorData, 'id', 'full_name')

                  if (oldText == undefined) {
                    IncluirAcompanhamento('Consultor definido: ' + newText)
                  } else {
                    IncluirAcompanhamento('Consultor alterado: ' + oldText + ' para ' + newText)
                  }
                }
                setConsultorIdValue(e.value)
              }}
              value={consultorIdValue}
            />
          )}</div>
        </div>


        {/* Acompanhamentos */}
        <div className={'pt-4'}>Registros</div>
        <div className="d-flex pb-2">
          <TextBox
            className={'me-2'}
            width={'100%'}
            placeholder="Escreva aqui uma atualização do atendimento"
            onValueChanged={(e) => {
              setAcompanhamentoValue(e.value)
            }}
            value={acompanhamentoValue}
          />
          <Button width={120} text="Incluir" type='default' onClick={OnClickIncluirAcompanhamento} />
        </div>
        <DataGrid className=""
          id="gridacompanhamentos"
          showBorders={true}
          dataSource={acompanhamentosData}
          repaintChangesOnly={true}
          columnAutoWidth={true}
          allowColumnResizing={false}
          allowColumnReordering={false}
          height="150"
          width="100%"
          columnWidth={'auto'}
        >
          <Scrolling mode="standard" useNativ={true} showScrollbar="always" />
          <Selection mode="single" />
          <Column dataField="id" caption="ID" visible={false} />
          <Column dataField="data_hora" width="120" caption="Inclusão" visible={true} dataType={"datetime"} />
          <Column dataField="consultor_nome" visible={true} caption="Consultor" />
          {/* //<Column dataField="consultor_responsavel_id" visible={true} caption="Consultor"><Lookup dataSource={dxCustomStore('/sys/user/list')} displayExpr="full_name" valueExpr="id" /></Column> */}
          <Column dataField="descricao" caption="Acompanhamento" visible={true} width="200" />
          <Summary><GroupItem column="id" displayFormat="{0}" summaryType="count" /></Summary>
        </DataGrid>


        <div className="d-flex bd-highlight">
          <div className="p-2 flex-grow-1 bd-highlight">
            <a href={"https://wa.me/55" + props.alunoData.aluno_telefone_celular} target="_blank">WhatsApp Web - Abrir conversa</a>
          </div>
          <div className="p-2 bd-highlight"><button type="button" className="btn btn-success" onClick={OnClickSalvar}>Salvar</button></div>
          <div className="p-2 bd-highlight"><button type="button" className="btn btn-danger" onClick={OnClickCancelar} >Cancelar</button></div>
        </div>

      </ScrollView>
    </Popup >
  )
}