import React, { useState, useEffect } from 'react';
import { Pager, Paging, ColumnChooser, Summary, GroupItem, Grouping, GroupPanel, TotalItem, DataGrid, Column, Selection, Scrolling, Popup, Lookup, Form, HeaderFilter, SearchPanel } from 'devextreme-react/data-grid';

import 'devextreme-react/text-area';

import SibeComponents from '../../../components/drop-down-box/SibeComponents'
import { customStoreWithToken, getDataSource, getDataSourceByPost, dxCustomStore } from '../../../api/dataSource'
import { useAuth } from '../../../contexts/auth'
import { GetPolosAccess } from '../../../config/user_profile'

import CandidatoForm from './CandidatoForm'

import Main from '../../../components/main/Main'
let sibeComponents = new SibeComponents();


const URL = '/gestao/relacao_candidatos_inscritos'

export default function (props) {

    console.log(props)

    const { user } = useAuth()
    const [dataRows, setDataRows] = useState([])
    // const [polos, setPolos] = useState([])

    const [polosValue, setPolosValue] = useState([])
    const [polosData, setPolosData] = useState([])

    const [anoSemestre, setAnoSemestre] = useState(-1)
    const [anoSemestreData, setAnoSemestreData] = useState([])
    const [visao, setVisao] = useState(-1)



    const [formOpen, setFormOpen] = useState(false)
    const [candidatoData, setCandidatoData] = useState('0')
    // const [consultoresData, setConsultoresData] = useState([])

    const [loadComplete, setLoadComplete] = useState(false)

    useEffect(() => {
        const load = async () => {
            const _polos = await GetPolosAccess('comercial_relacao_candidatos_inscritos', user.id)
            setPolosData(_polos)

            const anosSemestres = await getDataSource('/comercial/relacao_candidatos_inscritos/list-ano-semestre', 'GET')
            setAnoSemestreData(anosSemestres.data)
            setAnoSemestre(anosSemestres.data[0])
            upDateDataGridRows(polosData, anosSemestres.data[0])
            // updateConsultoresData()
        }
        load()
        setLoadComplete(true)
    }, []);


    const onPolosValueChange = (value) => {
        setPolosValue(value)
        if (loadComplete) upDateDataGridRows(value, anoSemestre)
    }
    const onAnoSemestreValueChange = (value) => {
        setAnoSemestre(value)
        if (loadComplete) upDateDataGridRows(polosValue, value)
    }
    const onVisaoValueChange = (value) => setVisao(value)


    const upDateDataGridRows = async (polos, ano_semestre = anoSemestre) => {
        const body = {
            ano_semestre: ano_semestre,
            polos: polos
        }
        const data = await getDataSourceByPost(URL, body)
        setDataRows(data.data)
    }

    const renderRegistros = (cellData) => {
        let renderCell = cellData.data.registros

        if (cellData.data.registros == 0) {
            renderCell = <div>
                <span className="text-danger"> {cellData.data.registros} </span>
            </div >
        }

        return renderCell
    }



    const renderAmbientacaoAlunoAvisadoIcon = (cellData) => buildAmbientacaoIcon(cellData.data.ambientacao_aluno_avisado_label)
    const renderAmbientacaoAlunoCienteIcon = (cellData) => buildAmbientacaoIcon(cellData.data.ambientacao_aluno_ciente_label)
    const renderAmbientacaoConcluidaIcon = (cellData) => buildAmbientacaoIcon(cellData.data.ambientacao_concluida_label)


    const buildAmbientacaoIcon = (value) => {
        let renderCell = <div></div>

        if (value == 'SIM') {
            renderCell = <div><span className="text-success"><i className="m-0 p-0 me-1 fas fa-check"></i></span></div >
        } else {

            renderCell = <div><span className="text-danger"><i className="text-danger m-0 p-0 me-1 fas fa-times fw-bold"></i></span></div >
        }

        return renderCell

    }


    const renderBtnEdit = (cellData) => {
        return (
            <button className="m-0 p-0 btn btn-sm" type="button" onClick={async () => {
                setCandidatoData(cellData.data)
                setFormOpen(true)
            }}>
                <span><i className="m-0 p-0 me-1 fas fa-pen"></i></span>
            </button>
        )
    }

    // const updateConsultoresData = async () => {
    //     const body = { polos: polosData }
    //     const data = await getDataSourceByPost('/gestao/relacao_candidatos_inscritos/list-consultor', body)
    //     setConsultoresData(data.data)
    // }


    const onRowPrepared = (e) => {
        if (e.rowType == 'data') {
            switch (e.data.inscricao_status_id) {
                // case 1: // Planejada
                //     if (e.data.abertura_aprovada == '1') {
                //         e.rowElement.style.backgroundColor = '#a8e6cf';
                //     } else {
                //         e.rowElement.style.backgroundColor = '#ffeead';
                //     }
                //     break;
                case 13: // Aguard. Análise de Aprov.
                    e.rowElement.style.backgroundColor = '#ffe45e';
                    break;
                // case 3: // Realizada
                //     e.rowElement.style.backgroundColor = '#3b7dd8';
                //     break;

                case 7: // Inscrição Duplicada
                    e.rowElement.style.backgroundColor = '#AACB73';
                    break;

                case 12: // Cand. Não Aprov. Anális. Aprov.
                    e.rowElement.style.backgroundColor = '#f4989c';
                    break;

                case 10: // Pendencia Financeira
                    e.rowElement.style.backgroundColor = '#f4989c';
                    break;

                case 3: // Sem Interesse
                    e.rowElement.style.backgroundColor = '#f4989c';
                    break;

                case 5: // Em Negociação
                    e.rowElement.style.backgroundColor = '#90f1ef';
                    break;





                default:
                    break;
            }
        }

    }


    return (<Main title={'/  Comercial  /  Relação De Candidados Inscritos'} privileges={props.privileges}>

        <div className="row">
            <div className="col-4 p-0 m-0">
                <div className="d-flex flex-column ps-3">
                    <div className="p-0 m-0 fw-bold">Polos:</div>
                    <div className="p-0 m-0">{sibeComponents.SelectMultiple(polosData, polosValue, onPolosValueChange, true)}</div>
                </div>
            </div>
            <div className="col-2 p-0 m-0">
                <div className="d-flex flex-column ps-3">
                    <div className="p-0 m-0 fw-bold">Ano/Semestre:</div>
                    <div className="p-0 m-0">{sibeComponents.SelectMultiple(anoSemestreData, anoSemestre, onAnoSemestreValueChange, false)}</div>
                </div>
            </div>

        </div>

        <div className="row mt-2">

        </div>

        <DataGrid className="mt-3 border-dark border-top"
            id="grid"
            showBorders={true}
            dataSource={dataRows}
            repaintChangesOnly={true}
            columnAutoWidth={true}
            allowColumnResizing={true}
            allowColumnReordering={true}
            height="70vh"
            width="100%"
            hoverStateEnabled={true}
            onRowPrepared={onRowPrepared}
        >
            <ColumnChooser enabled={true} height={"90vh"} width={300} allowSearch={true} />
            <Grouping autoExpandAll={false} />
            <GroupPanel visible={true} />
            <Paging defaultPageSize={500} />
            <Pager showPageSizeSelector={true} allowedPageSizes={[50, 100, 250, 500]} showInfo={true} />
            <HeaderFilter visible={true} />
            <SearchPanel visible={true} width={240} placeholder="Pesquisar..." />
            <Scrolling mode="standard" useNativ={true} showScrollbar="always" />
            <Selection mode="single" />

            <Column dataField="situacao" caption="Status (SIAA)" groupIndex={0} visible={true} width="120" autoExpandGroup={false} />

            <Column dataField="id" width="30" caption="" visible={true} alignment="center" cellRender={renderBtnEdit} allowSorting={false} allowFiltering={false} allowReordering={false} allowGrouping={false} />
            <Column dataField="data_inscricao" width="110" caption="Data" visible={true} dataType={"date"} defaultSortOrder="desc" alignment="center" />
            <Column dataField="nome" caption="Nome" visible={true} width="180" />


            <Column dataField="ambientacao_aluno_avisado_label" caption="Amb. Aluno Avisado" cellRender={renderAmbientacaoAlunoAvisadoIcon} visible={true} width="70" alignment="center" />
            <Column dataField="ambientacao_aluno_ciente_label" caption="Amb. Aluno Ciente" cellRender={renderAmbientacaoAlunoCienteIcon} visible={true} width="70" alignment="center" />
            <Column dataField="ambientacao_concluida_label" caption="Amb. Concluída" cellRender={renderAmbientacaoConcluidaIcon} visible={true} width="70" alignment="center" />



            <Column dataField="inscricao_status_nome" caption="Status (Sibe)" visible={true} width="150" />


            

            <Column dataField="registros" alignment="center" caption="Registros" visible={true} width="60" cellRender={renderRegistros} />
            <Column dataField="total_inscricoes" alignment="center" caption="Qtd. Insc." visible={true} width="60" />
            <Column dataField="polo_nome" caption="Polo" visible={true} width="120" />


            <Column dataField="consultor_nome" caption="Consultor" width="100" visible={true} />
            <Column dataField="data_matricula" width="110" caption="Matrícula" visible={true} dataType={"date"} alignment="center" />
            {/* <Column dataField="semestre" caption="Semestre" visible={false} width="100" /> */}
            <Column dataField="fone_cel" caption="Fone Cel." visible={true} width="110" />
            <Column dataField="cpf" caption="CPF" visible={true} width="100" />
            {/* <Column dataField="curso_grau_id" visible={true} caption="Grau" width="120" >
                <Lookup dataSource={customStoreWithToken('/sys/curso_grau')} displayExpr="nome" valueExpr="id" />
            </Column> */}
            <Column dataField="curso" caption="Curso" visible={true} width="110" />
            
            {/* <Column dataField="fone_res" caption="Fone Res." visible={true} width="80" /> */}
            {/* <Column dataField="fone_com" caption="Fone Com." visible={true} width="80" /> */}
            <Column dataField="email" caption="E-mail" visible={true} width="80" />
            <Column dataField="inscricao" alignment="center" caption="Inscrição" visible={true} width="100" />
            <Column dataField="endereco_municipio_uf" caption="Município/UF" visible={true} width="150" />
            <Column dataField="instituicao_id" visible={false} caption="Instituição" >
                <Lookup dataSource={customStoreWithToken('/sys/instituicao')} displayExpr="nome" valueExpr="id" />
            </Column>

            <Summary>
                <TotalItem column="id" summaryType="count" />
                <GroupItem column="id" displayFormat="{0}" summaryType="count" />
            </Summary>
        </DataGrid>


        <CandidatoForm
            setFormOpen={setFormOpen}
            formOpen={formOpen}
            candidatoData={candidatoData}
            polos={polosValue}
            upDateDataGridRows={upDateDataGridRows}
        //consultoresData={consultoresData}
        />


    </Main>
    )
}