import React from 'react';
import Main from '../../components/main/Main'
export default function (props) {


    return (<Main title={""} is_home={true}>
        <ul class="list-group">
            <a href="#" class="list-group-item list-group-item-action list-group-item-success">View Aluno</a>
            <li class="list-group-item d-flex justify-content-between align-items-center">
                Pendência de Documentos -[Instituição, Polo, RGM]<span class="badge bg-primary rounded-pill">0%</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center">
                Financeiro -[CPF]<span class="badge bg-primary rounded-pill">0%</span>
            </li>


            <a href="#" class="mt-3 list-group-item list-group-item-action list-group-item-success">Gestão</a>
            <li class="list-group-item d-flex justify-content-between align-items-center">
                Gerenciamento de CS - Customer Success
                <span class="badge bg-primary rounded-pill">0%</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center">
                Integrar com Sales Force
                <span class="badge bg-primary rounded-pill">0%</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center">
                Manuais: Qualquer coisa que for fazer deverá existir um procedimento por escrito de como fazer
                <span class="badge bg-primary rounded-pill">0%</span>
            </li>


            <a href="#" class="mt-3 list-group-item list-group-item-action list-group-item-success">Gestão - Relação De Alunos Sem Rematrícula</a>
            <li class="list-group-item d-flex justify-content-between align-items-center">
                Modelar uma funcionalidade para acompanhar/mostrar os últimos registros. Dois Grid talvez.
                <span class="badge bg-primary rounded-pill">0%</span>
            </li>

            <a href="#" class="mt-3 list-group-item list-group-item-action list-group-item-success">Gestão - Relação De Alunos Inscritos</a>
            <li class="list-group-item d-flex justify-content-between align-items-center">
                Modelagem<span class="badge bg-primary rounded-pill">0%</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center">
                Construção do ETL<span class="badge bg-primary rounded-pill">0%</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center">
                Construção das Views<span class="badge bg-primary rounded-pill">0%</span>
            </li>








            <a href="#" class="mt-3 list-group-item list-group-item-action list-group-item-success">Leads</a>
            <li class="list-group-item d-flex justify-content-between align-items-center">
                Logar alterações<span class="badge bg-primary rounded-pill">0%</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center">
                Criar opção de agendamentos<span class="badge bg-primary rounded-pill">0%</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center">
                Mostrar acompanhamentos no grid<span class="badge bg-primary rounded-pill">0%</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center">
                Pintar celula prox. acompanhamento de vermelho<span class="badge bg-primary rounded-pill">0%</span>
            </li>


            <a href="#" class="mt-3 list-group-item list-group-item-action list-group-item-success">Sistema</a>
            <li class="list-group-item d-flex justify-content-between align-items-center">
                Logar acessos e mostrar na tela inicial<span class="badge bg-primary rounded-pill">0%</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center">
                Finalizar tela de usuários
                <span class="badge bg-primary rounded-pill">0%</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center">
                Logar último acesso na tela de usuários
                <span class="badge bg-primary rounded-pill">0%</span>
            </li>

        </ul>
    </Main>)
}