import React from 'react'
import Header from './Header'
import './Main.css'

export default props => {
    return <React.Fragment>
        <main>
            <Header />
            <span className="h6 p-3"><i className="pe-2 fas fa-home fa-sm"></i>{props.title} </span>
            <div id='content-container' className="p-3">
                {props.children}
            </div>
        </main>
        {/* <Footer /> */}
    </React.Fragment>

}