import React, { useState, useEffect } from 'react';

import { Pager, Paging, ColumnChooser, Summary, GroupItem, Grouping, GroupPanel, TotalItem, DataGrid, Column, Selection, Scrolling, Popup, Lookup, Form, HeaderFilter, SearchPanel } from 'devextreme-react/data-grid';
import { InputSelect } from '../../../components/Layout/Inputs/Select'

import 'devextreme-react/text-area';
import { customStoreWithToken, getDataSource, getDataSourceByPost, dxCustomStore } from '../../../api/dataSource'

import Main from '../../../components/main/Main'
import { useAuth } from '../../../contexts/auth'
import { GetPolosAccess } from '../../../config/user_profile'

const visoesData = [
    { id: 1, nome: 'Polo-Situação-Grau' },
    { id: 2, nome: 'Matricula-Polo' }
]

const URL = '/academico/relacao_aluno_matriculado'

export default function (props) {
    const { user } = useAuth()

    const [dataRows, setDataRows] = useState([])
    // const [polos, setPolos] = useState([])

    const [polosValue, setPolosValue] = useState([])
    const [polosData, setPolosData] = useState([])

    const [anoSemestre, setAnoSemestre] = useState(-1)
    const [anoSemestreData, setAnoSemestreData] = useState([])
    const [visao, setVisao] = useState(-1)

    const [loadComplete, setLoadComplete] = useState(false)

    useEffect(() => {
        const load = async () => {

            const _polos = await GetPolosAccess('academico_relacao_alunos_matriculados', user.id)
            console.log(_polos)


            const anosSemestres = await getDataSource(URL + '/list-ano-semestre', 'GET')
            setAnoSemestreData(anosSemestres.data)
            setAnoSemestre(anosSemestres.data[0])

            setSemestreValue(anosSemestres.data[0])

            setPolosData(_polos)
            // upDateDataGridRows(props.privileges.polos, anosSemestres.data[0])
        }
        load()
        setLoadComplete(true)
    }, []);


    const onPolosValueChange = (value) => {
        setPoloValue(value)
        //setPolos(value)
        if (loadComplete) upDateDataGridRows(value, anoSemestre)
    }
    const onAnoSemestreValueChange = (value) => {
        setAnoSemestre(value)
        if (loadComplete) upDateDataGridRows(polosValue, value)
    }
    const onVisaoValueChange = (value) => setVisao(value)


    const upDateDataGridRows = async (polos, ano_semestre) => {
        const body = {
            ano_semestre: ano_semestre,
            polos: polos
        }
        const data = await getDataSourceByPost(URL + '/list', body)
        console.log(data.data)
        setDataRows(data.data)
    }

    const [poloValue, setPoloValue] = useState([])
    const [semestreValue, setSemestreValue] = useState('')
    const polosOnChange = (value) => upDateDataGridRows(value, semestreValue.nome)
    const semestreOnChange = (value) => upDateDataGridRows(poloValue, value.nome)

    return (<Main title={'/  Acadêmico  /  Relação De Alunos Matriculados'} privileges={props.privileges}>

        <div className="row pb-2">
            <div className="col-sm-4"><InputSelect caption={'Polo'}
                data={polosData}
                value={poloValue}
                isMulti={true}
                setValue={setPoloValue}
                onChange={polosOnChange} />
            </div>
            <div className="col-sm-2"><InputSelect caption={'Semestre Rematrículado'} data={anoSemestreData} value={semestreValue} setValue={setSemestreValue} onChange={semestreOnChange} /></div>
        </div>

        <DataGrid className="mt-3 border-dark border-top"            
            id="grid"
            showBorders={true}
            dataSource={dataRows}
            repaintChangesOnly={true}
            columnAutoWidth={true}
            allowColumnResizing={true}
            allowColumnReordering={true}
            height="70vh"
            width="100%"
            hoverStateEnabled={true}
        >
            <ColumnChooser enabled={true} height={"90vh"} width={300} allowSearch={true} />
            <Grouping autoExpandAll={false} />
            <GroupPanel visible={true} />
            <Paging defaultPageSize={500} />
            <Pager showPageSizeSelector={true} allowedPageSizes={[50, 100, 250, 500]} showInfo={true} />
            <HeaderFilter visible={true} />
            <SearchPanel visible={true} width={240} placeholder="Pesquisar..." />
            
            <Scrolling mode="standard" useNativ={true} showScrollbar="always" />
            <Selection mode="single" />
            
            <Column dataField="id" caption="ID" visible={false} />
            <Column dataField="data_matricula" width="120" caption="Matrícula" visible={true} dataType={"date"} defaultSortOrder="desc" alignment="center" />

            <Column dataField="data_matricula_ano_mes" caption="Matricula Mês/Ano" width="100" visible={true} alignment="center" />

            <Column dataField="polo_id" visible={true} caption="Polo" autoExpandGroup={false}>
                <Lookup dataSource={dxCustomStore('/sys/polo')} displayExpr="nome" valueExpr="id" />
            </Column>

            <Column dataField="aluno_nome" caption="Aluno" visible={true} width="250" />
            <Column dataField="matricula_situacao_id" visible={true} caption="Situação" width="120">
                <Lookup dataSource={customStoreWithToken('/sys/matricula_situacao')} displayExpr="nome" valueExpr="id" />
            </Column>
            <Column dataField="semestre" caption="Semestre" visible={false} width="100" />




            <Column dataField="curso_grau_id" visible={true} caption="Grau" width="120" >
                <Lookup dataSource={customStoreWithToken('/sys/curso_grau')} displayExpr="nome" valueExpr="id" />
            </Column>
            <Column dataField="curso_grau_id_e_id" visible={true} caption="Curso" >
                <Lookup dataSource={customStoreWithToken('/sys/curso')} displayExpr="nome" valueExpr="curso_grau_id_e_id" />
            </Column>
            <Column dataField="rgm" caption="RGM" visible={true} width="100" alignment="center" />

            <Column dataField="serie" caption="Série" width="100" visible={true} alignment="center" />
            <Column dataField="ingresso_ano_trimestre" width="100" caption="Ingresso" visible={true} alignment="center" />



            <Column dataField="instituicao_id" visible={false} caption="Instituição" >
                <Lookup dataSource={customStoreWithToken('/sys/instituicao')} displayExpr="nome" valueExpr="id" />
            </Column>

            <Summary>
                <TotalItem column="id" summaryType="count" />
                <GroupItem column="id" displayFormat="{0}" summaryType="count" />
            </Summary>
        </DataGrid>
    </Main>
    )
}