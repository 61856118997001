import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import token from '../config/token'

const server = process.env.REACT_APP_API_URL

// const API_URL = () => {
//     return 'http://api-education.sibe.cloud'
// }


const getHeader = () => {
    const authorization = token.get
    return {
        'Content-Type': 'application/json',
        'authorization': authorization
    }
}



export const dxDataSource = (apiURL) => new DataSource({
    load: async (options) => {
        try {
            const response = await fetch(server + apiURL, {
                headers: getHeader(),
            })
            const data = await response.json()

            if (response.status != 200) throw new Error(data.error)
            return {
                isOk: true,
                data: data
            }
        } catch (e) {
            console.error(e.message)
            return {
                isOk: false,
                data: [],
                message: e.message
            }
        }

        // const res = await api.get(apiURL)
        // return res
    },
    update: async (key, values) => {
        try {
            let keyPath = key
            if (typeof (key) == 'object') keyPath = key.id

            const response = await fetch(server + apiURL + `/${keyPath}`, {
                method: 'PUT',
                headers: getHeader(),
                body: JSON.stringify(values)
            });
            const data = await response.json();
            if (response.status != 200) throw new Error(data.error);
            return {
                isOk: true,
                data: data
            }
        } catch (e) {
            console.error(e.message)
            return {
                isOk: false,
                data: [],
                message: e.message
            };
        }
    },
    insert: async (values) => {
        try {
            const response = await fetch(server + apiURL, {
                method: 'POST',
                headers: getHeader(),
                body: JSON.stringify(values)
            });
            const data = await response.json();
            if (response.status != 200) throw new Error(data.error);
            return {
                isOk: true,
                data: data
            }
        } catch (e) {
            console.error(e.message)
            return {
                isOk: false,
                data: [],
                message: e.message
            };
        }
    },


    // remove: async (key) => await api.delete(apiURL + '/' + key.id),
    // insert: async (values) => await api.post(apiURL, values),
    // byKey: async (key) => {
    //     console.error('ATENÇÃO FUNÇÃO BY KEY NÃO IMPLEMENTADA')
    //     console.log(key)
    // }
})



const dxCustomStore = (URL, body, method = 'POST') => {
    try {
        return new CustomStore({
            load: async (loadOptions) => {

                if (body) body.loadOptions = loadOptions

                let params = {
                    method: method,
                    body: JSON.stringify(body),
                    headers: getHeader(),
                }

                if (method == 'GET') delete params.body

                const result = await fetch(server + URL, params)
                const json = await result.json()
                if (result.ok)
                    return json
                //return { data: json.data, totalCount: 45 }
                throw json
            },
            byKey: async (key, method = 'POST') => {
                const result = await fetch(server + URL + '/' + key, {
                    method: method,
                    headers: getHeader(),
                });
                const json = await result.json();
                if (result.ok) {
                    return json;
                }
                throw json;
            },

            update: async (key, values) => {
                console.log('update')


                let keyPath = key

                if (typeof (key) == 'object') keyPath = key.id

                console.log()
                console.log(values)


                const result = await fetch(server + URL + '/' + keyPath, {
                    method: 'POST',
                    headers: getHeader(),
                    body: JSON.stringify(values)
                });
                const json = await result.json();
                if (result.ok)
                    return json;
                throw json.error;
            },

            insert: async (values) => {
                console.log('insert')
                const result = await fetch(server + URL, {
                    method: 'POST',
                    headers: getHeader(),
                    body: JSON.stringify(values)
                });
                const json = await result.json();

                console.log(json)

                if (result.ok)
                    return json;
                throw json;
            },
        })
    } catch (error) {
        console.log(error)
        return error.message
    }
}




const postData = async (URL, body) => {
    try {
        const authorization = token.get
        const headers = {
            'Content-Type': 'application/json',
            'authorization': authorization
        }

        const response = await fetch(server + URL, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body)
        });

        const data = await response.json();

        if (response.status == 450) throw new Error(data.error) // Somente para leads -> inclusao_lote

        if (response.status != 200) throw new Error(data)
        return {
            isOk: true,
            data: data
        }
    }

    catch (e) {
        return {
            isOk: false,
            message: e.message,
        };
    }
}



const getDataSourceByPost = async (URL, body) => {
    try {
        const authorization = token.get
        const headers = {
            'Content-Type': 'application/json',
            'authorization': authorization
        }

        const response = await fetch(server + URL, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body)
        });

        const data = await response.json();
        if (response.status != 200) throw new Error(data.error);
        return {
            isOk: true,
            data: data
        }
    }

    catch (e) {
        console.log(e.message)
        return {
            isOk: false,
            data: [],
            message: e.message
        };
    }
}

const getDataSource = async (URL, method = "GET") => {
    try {
        const authorization = token.get

        const headers = {
            'Content-Type': 'application/json',
            'authorization': authorization
        }

        const response = await fetch(server + URL, {
            method: method,
            headers: headers,
        });
        const data = await response.json();
        if (response.status != 200) throw new Error(data.error);
        return {
            isOk: true,
            data: data
        }
    }

    catch (e) {
        console.log(e.message)
        return {
            isOk: false,
            data: [],
            message: e.message
        };
    }
}

const customStoreWithToken = (URL, body) => {
    URL = server + URL
    const authorization = token.get

    const headers = {
        'Content-Type': 'application/json',
        'authorization': authorization
    }

    //console.log(authorization)

    return new CustomStore({
        key: 'id',
        loadMode: "processed",
        //cacheRawData: "false",

        byKey: async (key) => {
            const result = await fetch(URL + '/' + key, {
                method: 'GET',
                headers: headers,
            });
            const json = await result.json();
            if (result.ok) {

                console.log('json: ' + json.nome);
                return json;
            }
            throw json;
        },

        load: async (loadOptions) => {
            const result = await fetch(URL, {
                method: 'GET',
                headers: headers,
            });
            const json = await result.json();
            if (result.ok)
                return json;
            throw json;
        },

        insert: async (values) => {
            const result = await fetch(URL, {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(values)
            });
            const json = await result.json();
            if (result.ok)
                return json;
            throw json.error;
        },

        update: async (key, values) => {
            const result = await fetch(URL + '/' + key, {
                method: 'PUT',
                headers: headers,
                body: JSON.stringify(values)
            });
            const json = await result.json();
            if (result.ok)
                return json;
            throw json.error;
        },

        remove: async (key) => {
            const result = await fetch(URL + '/' + key, {
                method: 'DELETE',
                headers: headers,
            });
            const json = await result.json();
            if (result.ok)
                return json;
            throw json.error;
        }
    })
}

// const dxCustomStore = (URL, body, loadMethod = 'GET') => {
//     URL = server + URL
//     const authorization = token.get

//     const headers = {
//         'Content-Type': 'application/json',
//         'authorization': authorization
//     }

//     return new CustomStore({
//         key: 'id',
//         loadMode: "processed",
//         //cacheRawData: "false",

//         byKey: async (key) => {
//             console.log(URL + ' byKey')
//             const result = await fetch(URL + '/' + key, {
//                 method: 'GET',
//                 headers: headers,
//             });
//             const json = await result.json();
//             if (result.ok) {

//                 console.log('json: ' + json.nome);
//                 return json;
//             }
//             throw json;
//         },

//         load: async (loadOptions) => {
//             console.log(URL + ' load')
//             const result = await fetch(URL, {
//                 method: loadMethod,
//                 body: JSON.stringify(body),
//                 headers: headers,
//             });
//             const json = await result.json();
//             if (result.ok)
//                 return json;
//             throw json;
//         },

//         insert: async (values) => {
//             const result = await fetch(URL, {
//                 method: 'POST',
//                 headers: headers,
//                 body: JSON.stringify(values)
//             });
//             const json = await result.json();
//             if (result.ok)
//                 return json;
//             throw json.error;
//         },

//         update: async (key, values) => {
//             const result = await fetch(URL + '/' + key, {
//                 method: 'PUT',
//                 headers: headers,
//                 body: JSON.stringify(values)
//             });
//             const json = await result.json();
//             if (result.ok)
//                 return json;
//             throw json.error;
//         },

//         remove: async (key) => {
//             const result = await fetch(URL + '/' + key, {
//                 method: 'DELETE',
//                 headers: headers,
//             });
//             const json = await result.json();
//             if (result.ok)
//                 return json;
//             throw json.error;
//         }
//     })
// }

export { customStoreWithToken, getDataSource, getDataSourceByPost, postData, dxCustomStore }