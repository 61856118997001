import React, { useState } from 'react';
import Select from 'react-select';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import './SibeComponents.css'


const getThemeCSS = () => {
    let themeCSS
    const themeStorage = localStorage.getItem('theme') || "generic.light"
    if (themeStorage == 'generic.light') themeCSS = '-light'
    if (themeStorage == 'generic.dark') themeCSS = '-dark'
    return themeCSS
}









const ListBox = (label, data, inputRef) => {
    return <TextField
        inputRef={inputRef}
        select
        label={label}
        //value={value}
        defaultValue
        fullWidth={true}
        size="small"
        variant="standard"

    //onChange={onValueChange}
    >
        {data.map((option) => (
            <MenuItem key={option.id} value={option.id}>
                {option.nome}
            </MenuItem>
        ))}
    </TextField>
}

const TextBoxRef = (label, inputRef) => {
    return <TextField
        inputRef={inputRef}
        label={label}
        //value={value}
        fullWidth={true}
        size="small"
        variant="standard"
    //onChange={onValueChange}
    />
}

const TextBox = (label, value, onValueChange, formName) => {
    return <TextField
        id={"inputSelect" + getThemeCSS()}
        name={formName}
        label={label}
        value={value}
        fullWidth={true}
        size="small"
        variant="standard"
        onChange={onValueChange}
    />
}

export { ListBox, TextBox, TextBoxRef }

export default class SibeComponents {

    SelectMultiple = (data, value, onChange, multi = false) => {


        return <Select
            id={"inputSelect" + getThemeCSS()}
            value={value}
            onChange={onChange}
            isMulti={multi}
            //isClearable={true}
            isSearchable={true}
            options={data}
            fullWidth
            getOptionLabel={option => option.nome}
            getOptionValue={option => option.id}
        />
    }

    SelectMultipleTEMP = (data, value, onChange, multi = false) => {
        return <Select
            id={"inputSelect" + getThemeCSS()}
            value={value}
            onChange={onChange}
            isMulti={multi}
            //isClearable={true}
            isSearchable={true}
            options={data}
            fullWidth
            getOptionLabel={option => option.full_name}
            getOptionValue={option => option.id}
        />
    }



}