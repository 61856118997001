import React, { useState, useEffect } from 'react';
import { Pager, Paging, Editing, Summary, GroupItem, Grouping, GroupPanel, DataGrid, Column, Selection, Scrolling, Popup, Lookup, Form, HeaderFilter, SearchPanel } from 'devextreme-react/data-grid';
import 'devextreme-react/text-area';
//import SibeComponents from '../../../components/drop-down-box/SibeComponents'
import { dxDataSource, getDataSource, getDataSourceByPost, dxCustomStore } from '../../api/dataSource'
import { useAuth } from '../../contexts/auth'
import Main from '../../components/main/Main'
const URL = '/sistema_acesso'

export default function (props) {
    const { user } = useAuth()


    console.log(user)

    const [isAdmin, setIsAdmin] = useState(false)

    useEffect(() => {
        if (user.admin == 1) setIsAdmin(true)
    }, [])


    return (<Main title={'/  Acessos'} privileges={props.privileges}>

        <DataGrid className="mt-3"
            id="grid"
            showBorders={true}
            dataSource={dxDataSource(URL)}
            repaintChangesOnly={true}
            columnAutoWidth={true}
            allowColumnResizing={true}
            allowColumnReordering={true}
            height="70vh"
            width="100%"
        >
            {/* <ColumnChooser enabled={true} height={"90vh"} width={300} allowSearch={true} /> */}
            <Grouping autoExpandAll={false} />
            <GroupPanel visible={true} />
            <Paging defaultPageSize={500} />
            <Pager showPageSizeSelector={true} allowedPageSizes={[50, 100, 250, 500]} showInfo={true} />
            <HeaderFilter visible={true} />
            <SearchPanel visible={true} width={240} placeholder="Pesquisar..." />
            <Scrolling mode="standard" useNativ={true} showScrollbar="always" />
            <Selection mode="single" />


            <Editing
                mode="batch"
                allowUpdating={isAdmin}
                allowAdding={isAdmin}
            // allowDeleting={true}
            // selectTextOnEditStart={this.state.selectTextOnEditStart}
            // startEditAction={this.state.startEditAction}
            />


            {/* <Column dataField="semestre" caption="Semestre" groupIndex={0} visible={true} width="100" defaultSortOrder="desc" /> */}
            <Column dataField="sistema" caption="Sistema" visible={true} width="150" groupIndex={0} autoExpandGroup={false} />
            <Column dataField="polo_label" caption="Polo" visible={true} width="120" />
            <Column dataField="url" caption="Acesso" visible={true} width="200" />
            <Column dataField="login" caption="Login" visible={true} width="150" />
            <Column dataField="senha" caption="Senha" visible={true} width="150" />
            <Column dataField="observacao" caption="Observação" visible={true} />


            {/* <Column dataField="rematricula_situacao_sibe_nome" caption="Situação Sibe" visible={true} width="150" groupIndex={0} autoExpandGroup={false} />
            <Column dataField="polo_nome" caption="Polo" visible={true} autoExpandGroup={false} />
            <Column dataField="id" caption="" visible={true} alignment="center" cellRender={renderBtnEdit} allowSorting={false} allowFiltering={false} allowReordering={false} allowGrouping={false} />
            <Column dataField="aluno_nome" caption="Aluno" visible={true} width="250" />
            <Column dataField="registros" alignment="center" caption="Registros" visible={true} width="100" cellRender={renderRegistros} />
            <Column dataField="rematricula_consultor_name" caption="Consultor" visible={true} />
            <Column dataField="rgm" caption="RGM" alignment="center" visible={true} width="100" />
            <Column dataField="serie" caption="Série" alignment="center" width="100" visible={true} />
            <Column dataField="ingresso_ano_trimestre" alignment="center" width="100" caption="Ingresso" visible={true} />
            <Column dataField="curso_grau_nome" caption="Grau" visible={true} />
            <Column dataField="curso_nome" caption="Curso" visible={true} />
            <Column dataField="data_matricula" alignment="center" caption="Matrícula" visible={true} dataType={"date"} /> */}

            {/* <Summary><GroupItem column="id" displayFormat="{0}" summaryType="count" /></Summary> */}
        </DataGrid>
    </Main>
    )
}